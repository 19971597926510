import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import notify from 'devextreme/ui/notify';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { useAuth } from '../../hooks/useAuth';
import { GiacenzaDettInterface, RicercaProdInterface } from '../../interfaces/bancaDati';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { DettagliOrdineInterface, GiacenzeVendutoInterface, TestataOrdineInterface } from '../../interfaces/ordiniRda';
import { TabellaCompletaInterface, TabellaSempliceInterface } from '../../interfaces/tabelle';
import { RestServices } from '../../services/restServices';
import { BoxTestoCerca, NumberBoxFiltro } from '../../widget/Editor';
import { DialogConferma, Loading } from '../../widget/Notifications';
import { TabellaCompleta, TabellaCompletaAlternate, TabellaSemplice, TabellaSempliceEdit } from '../../widget/Tabelle';
import { urlAnagrafiche, urlBancaDati, urlCommon, urlConsultazione, urlOrdiniRda } from '../costanti';
import DialogAggiungiProdOrdine from '../../common/DialogAggiungiProdOrdine';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import TestataOrdineRda from './TestataOrdineRda';
import { DialogAggiungiProdottiDittaRda, DialogDifferenzeRda, DialogSconto } from './Dialog';
import { faClone, faInfoCircle, faPaste, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DrawerFiltri } from '../../widget/Drawer';
import GrigliaVendutoRda from './GrigliaVendutoAnnuo';
import { getCodFarmFromId, getDescrFarmFromId } from '../../funzioni/anagrafica';
import { FornitoriCentrInterface, ListaPagamentiInterface, OggettoInterface } from '../../interfaces/generiche';
import { compareValues } from '../../funzioni/operazioniSuDati';
import GrigliaVendutoAcquistatoRda from './GrigliaVendutoAnnuo';
import DataGrid from 'devextreme-react/data-grid';
import { alert } from 'devextreme/ui/dialog';

import Form, { GroupItem,
} from 'devextreme-react/form';
import { NewTabella } from '../../widget/nuova_tabella/newVersion';
import RadioGroup from 'devextreme-react/radio-group';

const SingoloOrdineRda = (props: {
    toggleMenu(stato: boolean): any,
    statoMenu: boolean,
    datiFarmacie: ListaFarmacieInterface[]
}) => {
    const auth = useAuth(urlOrdiniRda.auth);
    const authCommon = useAuth(urlCommon.auth);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    //venduto
    const [isLoadVisible, setIsLoadVisible] = useState(false);
    const loadPanelVenduto = <Loading isVisible={isLoadVisible} position="#colVendutoRda" />
    const [isLoadAltraFarmVisible, setIsLoadAltraFarmVisible] = useState(false);
    const loadPanelVendutoAltraFarm = <Loading isVisible={isLoadAltraFarmVisible} position="#colVendutoAltraFarmRda" />
    //acquistato
    const [isLoadVisibleAcquistato, setIsLoadVisibleAcquistato] = useState(false);
    const loadPanelAcquistato = <Loading isVisible={isLoadVisibleAcquistato} position="#colAcquistatoRda" />
    const [isLoadAltraFarmVisibleAcquistato, setIsLoadAltraFarmVisibleAcquistato] = useState(false);
    const loadPanelAcquistatoAltraFarm = <Loading isVisible={isLoadAltraFarmVisibleAcquistato} position="#colAcquistatoAltraFarmRda" />
    //giacenze
    const [isLoadVisibleGiacenze, setIsLoadVisibleGiacenze] = useState(false);
    const loadPanelGiacenze = <Loading isVisible={isLoadVisibleGiacenze} position="#colGiacenzeRda" />

    const idOrdine = window.location.href.split("?")[1];
    useEffect(() => {
        
        carica()
    }, [auth]);

    const carica = () => {
        if (auth) {
            setIsVisible(true);
            RestServices().rest('POST', idOrdine, urlOrdiniRda.getDettagliOrdine)
                .then(result => {
                    setIsVisible(false);
                    if (result && result.testata && result.lstRdaDettaglio) {
                        setTestata(result.testata);
                        setDettagliOrdine(result.lstRdaDettaglio);
                        setFarmSelez([result.testata.idFar]);
                        //if (result.lstRdaDettaglio.length > 0)
                        //    ricercaGiacenze(result.testata.idFar, result.lstRdaDettaglio[0].codCsf, result.lstRdaDettaglio[0].prodotto, result.lstRdaDettaglio[0].idRdaOrdineDettaglio);
                        const altraFarmacia = props.datiFarmacie.filter(farm => {
                            return farm.ID_FAR !== result.testata.idFar
                        })
                        setAltraFarmSelez([altraFarmacia[0].ID_FAR]);
                        //setSelectedRowKeys([result.lstRdaDettaglio[0]]);
                    } else {
                        notify({ position: "center", width: "auto", message: "Oops, si è verificato un problema. Ci scusiamo per il disagio" }, "error", 4000)
                    }
                })
        }
    }

    const [openDrawer, setOpenDrawer] = useState(true);
    const [inputCerca, setInputCerca] = useState<string>("");
    const [testata, setTestata] = useState<TestataOrdineInterface>();
    const [dettagliOrdine, setDettagliOrdine] = useState<DettagliOrdineInterface[]>([]);
    const [farmSelez, setFarmSelez] = useState<number[]>([]);
    const [altraFarmSelez, setAltraFarmSelez] = useState<number[]>();
    const [giacenzeVenduto, setGiacenzeVenduto] = useState<GiacenzeVendutoInterface[]>([]);
    const [cercaNuovoProd, setCercaNuovoProd] = useState<RicercaProdInterface[]>([]);
    const [aggiornaTabDett, setAggiornaTabDett] = useState(1);
    const [autorizzazione, setAutorizzazione] = useState<string[]>();
    const [statoAttuale, setStatoAttuale] = useState<number>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [tabellaGiacInstance, setTabellaGiacInstance] = useState<any>();
    const [descrProdotto, setDescrProdotto] = useState<string>(" ");
    const [codiceCsfSelez, setCodiceCsfSelez] = useState<number>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
    const [rigaTabSemplice, setRigaTabSemplice] = useState<any[]>();
    const [allowEditing, setAllowEditing] = useState<boolean>(true);
    const [isDialogDiffOpen, setIsDialogDiffOpen] = useState<boolean>(false);
    const [isDialogAggiungiProdottiDittaOpen, setIsDialogAggiungiProdottiDittaOpen] = useState<boolean>(false);
    const [isDialogScontoOpen, setIsDialogScontoOpen] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [listaPagamenti, setListaPagamenti] = useState<ListaPagamentiInterface[]>();
    const [listaFornitori, setListaFornitori] = useState<FornitoriCentrInterface[]>();
    const handleClose = () => setIsDialogOpen(false);
    //const handleCloseAggiungiProdottiDitta = () => setIsDialogAggiungiProdottiDittaOpen(false);
    const handleOpen = () => setIsDialogOpen(true);
    const svuotaDialog = () => setCercaNuovoProd([]);
    const [dialogConfermaIsOpen, setDialogConfermaIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogConfermaIsOpen(false);
    const [messaggioConferma, setMessaggioConferma] = useState<any>();
    const [idTipPagamento, setIdTipPagamento] = useState<number | undefined>();
    const [tabellaUltimiAcquisti, setTabellaUltimiAcquisti] = useState<any>();

    const [listCodCsf, setListCodCsf] = useState<any>([])
    const [dittaLinea, setDittaLinea] = useState<any>([])

    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [disabledSconto, setDisabledSconto] = useState<boolean>(false)

    const [selectedIndex, setSelectedIndex] = useState<any>(0);

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        if (authCommon && testata) {
            RestServices().rest("POST", testata.idFar, urlCommon.getListaPagamenti).then(result => {
                if (result) setListaPagamenti(result);
            })

        }
    }, [authCommon, testata])

    useEffect(() => {
        RestServices().rest('POST', true, urlAnagrafiche.getFornitoreCentral).then(result => {
            if (result) setListaFornitori(result);
        })

    }, [])

    useEffect(() => {

        if (testata && testata.idStato ) {
            setStatoAttuale(testata.idStato);
            setIdTipPagamento(testata.idTipPagamento);


            let param = { idOrdine: testata?.id }

            RestServices().rest('POST', param, urlOrdiniRda.getListAutorizzazioni)
                .then(result => {
                    if (result) {
                        setAutorizzazione(result);
                    }
                })
        }

        

    }, [testata]);

    useEffect(() => {

        if (dettagliOrdine && testata && autorizzazione && dettagliOrdine.length > 0)
            ricercaGiacenze(testata.idFar, dettagliOrdine[0].codCsf ? dettagliOrdine[0].codCsf : 0,
                dettagliOrdine[0].prodotto ? dettagliOrdine[0].prodotto : "", dettagliOrdine[0].idRdaOrdineDettaglio ? dettagliOrdine[0].idRdaOrdineDettaglio : 0);


    },[autorizzazione, dettagliOrdine, testata])

    useEffect(() => {
        if (testata && autorizzazione) {
            if ((statoAttuale && (statoAttuale !== 2 && statoAttuale !== 1)) || !autorizzazione.includes("M")) {
                setAllowEditing(false);
                setAggiornaTabDett(aggiornaTabDett + 1);
            }
        }
    }, [testata, statoAttuale, autorizzazione])

    const ricercaGiacenze = async (idFar: number, codCsf: number, prodotto: string, idRdaOrdineDettaglio: number) => {
        //console.log("ciao")
        //console.log(idFar)
        //console.log(codCsf)
        //console.log(autorizzazione)
        //console.log(prodotto)
        //console.log(idRdaOrdineDettaglio)

        let lstIdFarTrasf : number [] = []

        if (codCsf && (autorizzazione)) {
            //console.log("AA")
            const listaIdFarmGruppo: number[] = [];
            if (props.datiFarmacie && props.datiFarmacie.length > 0) {
                const altreFarmacie = props.datiFarmacie.filter((farm) => {
                    return farm.ID_FAR !== idFar
                })
                altreFarmacie.forEach((farmacia) => {
                    listaIdFarmGruppo.push(farmacia.ID_FAR);
                })
                const params = {
                    lstFar: listaIdFarmGruppo,
                    codCsf: codCsf
                }
                RestServices().rest('POST', params, urlBancaDati.getGiacenzaDett).then(async result => {
                    if (result) {
                        if (result && result.message && result.severity) {
                            notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                        } else {
                            // non considerare le farmacie con giacenza vendibili a 0

                            let resultFilter = result.filter((item: any) => { return item.giacenzaVendibili > 0 });
                            //console.log(resultFilter)
                            resultFilter.forEach((item: GiacenzaDettInterface) => {
                                if (props.datiFarmacie && props.datiFarmacie.length > 0) {
                                    var descrFarm = "";
                                    
                                    if (item.idFar != 0) {
                                        //descrFarm = getDescrFarmFromId(props.datiFarmacie, item.idFar);
                                        descrFarm = getCodFarmFromId(props.datiFarmacie, item.idFar); 

                                        lstIdFarTrasf.push(item.idFar)
                                    }
                                    else {
                                        descrFarm = "Magazzino Centrale";
                                    }
                                    
                                    item.descrFarm = descrFarm;
                                }
                            })


                            let param = {
                                codCsf: codCsf,
                                idRdaDettaglio: idRdaOrdineDettaglio
                            }
                            RestServices().rest('POST', param, urlOrdiniRda.getQuantitaTrasf).then(resQ => {

                                if (resQ) {

                                    resultFilter.forEach((singResult: GiacenzaDettInterface) => {
                                        singResult.idRdaDettaglio = idRdaOrdineDettaglio
              
                                        if (resQ.length > 0 && resQ.filter((item: any) => {
                                            return item.ID_FAR === singResult.idFar
                                        }).length > 0) {
                                            resQ.forEach((singRes: OggettoInterface) => {
                                                if (singRes.ID_FAR === singResult.idFar) {
                                                    singResult['qtaFarTrasf'] = singRes.QTA
                                                }
                                                else {
                                                    
                                                }
                                                    
                                            })
                                        }
                                        else {
                                            singResult.qtaFarTrasf = 0
                                        }
                                            
                                        
                                    });
                                }
                                else {
                                    resultFilter.forEach((singResult: GiacenzaDettInterface) => {
                                        singResult.qtaFarTrasf = 0
                                    })
                                }


                            })


                            RestServices().rest('POST', codCsf, urlOrdiniRda.getVendutoMensile).then(async res => {
                                if (res) {
                                    resultFilter.forEach((singResult: GiacenzeVendutoInterface) => {
                                        res.forEach((singRes: OggettoInterface) => {
                                            if (singRes.ID_FAR === singResult.idFar) {
                                                singResult.vendutoMensile = singRes.QTA
                                            }
                                        })
                                    });

                            RestServices().rest('POST', codCsf, urlOrdiniRda.getVendutoAnnuoMedio).then(risultato => {
                                if (risultato) {
                                    resultFilter.forEach((singRisultato: GiacenzeVendutoInterface) => {
                                        risultato.forEach((singRes: OggettoInterface) => {
                                            if (singRes.ID_FAR === singRisultato.idFar) {
                                                singRisultato.vendutoAnnuoMedio = singRes.QTA
                                                singRisultato.mesiCopertura = singRisultato.vendutoAnnuoMedio != undefined && singRisultato.vendutoAnnuoMedio > 0 ? String((singRisultato.giacenzaVendibili / singRisultato.vendutoAnnuoMedio).toFixed(2)) : ""
                                            }
                                        })
                                    });

//                                    setGiacenzeVenduto(resultFilter);
                                    setDescrProdotto(prodotto);
                                    setCodiceCsfSelez(codCsf);
                                    const datiVendutoAltreFarm = JSON.parse(JSON.stringify(result));
                                    datiVendutoAltreFarm.sort(compareValues('giacenzaVendibili', 'desc'));
                                    setRigaTabSemplice([datiVendutoAltreFarm[0]]);
                                    setAltraFarmSelez([datiVendutoAltreFarm[0].idFar]);
                                    setOpenDrawer(false);
                                }
                            })


                            let listIdFar: any[] = []
                            listIdFar.push(idFar)
                            RestServices().rest('POST', { codCsf: codCsf, idFar: listIdFar, top: 10, flagTrasferito: false }, urlConsultazione.getListMagazCarichi)
                                .then(result => {
                                    if (result?.esito) {

                                        setTabellaUltimiAcquisti(result.response)
                                        //props.setIsLoadVisible(false);
                                    } else {
                                        //props.setIsLoadVisible(false);
                                    }
                                })
                                }
                            })

                            // chiamata per quantita in corso di trasferimento
                            let paramqtaTrasf = {
                                codCsf: codCsf,
                                idRdaDettaglio: idRdaOrdineDettaglio,
                                idFarOrdine: idFar,
                                lstFar: lstIdFarTrasf,
                            }

                            setIsLoadVisibleGiacenze(true)
                            const resQTR = await RestServices().rest('POST', paramqtaTrasf, urlOrdiniRda.getQuantitaTrasfRichiesta);
                            setIsLoadVisibleGiacenze(false)
                            if (resQTR) {
                                //console.log(resQTR)
                                resultFilter.forEach((singResult: GiacenzeVendutoInterface) => {
                                    resQTR.forEach((singRes: OggettoInterface) => {
                                        if (singRes[singResult.idFar]) {
                                            singResult['qtaFarTrasfRichiesta'] = singRes[singResult.idFar]
                                            singResult['tooltip'] = singRes['tooltip']
                                        }
                                        //else
                                        //    singResult.qtaFarTrasfRichiesta = 0
                                    })
                                });


                            }

                            
                            setGiacenzeVenduto(resultFilter);
                            

                            
                        }
                    }
                })
            }
        }
    }

    const columnsDettaglio = useMemo(() => CreaColonne([
        { caption: "IdRdaOrdineDettaglio", dataField: "idRdaOrdineDettaglio", visible:false},
        { caption: "Prodotto", dataField: "prodotto", allowEditing: false, width: 295 },
        { caption: "Cod.Prod.", dataField: "minsan", allowEditing: false, width: 80 },
        { caption: "Prz(€)", dataField: "prezzoListino", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, allowEditing: false, width: 50 },
        { caption: "Sc1(%)", dataField: "sconto1", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, width: 50 },
        { caption: "Sc2(%)", dataField: "sconto2", format: { currency: "EUR", precision: 2, type: "fixedPoint" },  width: 50 },
        { caption: "Sc3(%)", dataField: "sconto3", format: { currency: "EUR", precision: 2, type: "fixedPoint" },  width: 50 },
        { caption: "Costo(€)", dataField: "costo", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, width: 80 },
        { caption: "Costo Listino(€)", dataField: "costoListino", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, allowEditing: false, width: 60 },
        { caption: "IVA(%)", dataField: "iva", allowEditing: false, width: 50 },
        { caption: "Qta Appr", dataField: "quantitaApprovata", width: 55, allowEditing: false },
        { caption: "Qta", dataField: "quantita", width: 55 },
        { caption: "Qta Om", dataField: "quantitaOmaggio", width: 55 },
        { caption: "Qta Osg", dataField: "quantitaOverstockGruppo", width: 55, allowEditing: false },
        { caption: "Qta V.U.M", dataField: "venduto", allowEditing: false, width: 55 },
        { caption: "Ven.Med.", dataField: "vendutoMedioMensile", allowEditing: false, width: 60, format: {  precision: 2, type: "fixedPoint" } },
        { caption: "Giac", dataField: "giacenza", allowEditing: false, width: 60 },
        { caption: "Giac.Gruppo", dataField: "totGiacenza", allowEditing: false, width: 60, visible: autorizzazione && !autorizzazione.includes("O") ? true: false },
        { caption: "Mesi cop.", dataField: "mesiCopertura", allowEditing: false, width: 50, format: {  precision: 2, type: "fixedPoint" } },
        //{ caption: "EAN", dataField: "ean", allowEditing: false },
        {
            caption: " ", type: "buttons", width: 50, fixed: false, buttons: [{
                hint: "Elimina il prodotto",
                icon: "minus",
                onClick: (e: any) => {
                    if (autorizzazione && statoAttuale) {
                        if (
                            (statoAttuale === 2 && autorizzazione.includes("M"))
                            ||
                            (statoAttuale === 1)
                        ) {
                            const idRiga = e.row.data.idRdaOrdineDettaglio;
                            const dati = [...dettagliOrdine];
                            const datiDelete : any[]= []

                            dati.forEach((riga) => {
                                
                                if (riga.idRdaOrdineDettaglio === idRiga) {
                                    riga.quantita = 0;
                                    riga.quantitaOmaggio = 0;
                                    RestServices().rest('POST', riga, urlOrdiniRda.deleteDettagliOrdine).then(result => {
                                        if (!result) {
                                            notify({ position: "center", width: "auto", message: "Non è stato possibile modificare il campo desiderato. Si prega di riprovare" }, "error", 4000)
                                            return;
                                        } else if (result && result.idRdaOrdineDettaglio) {

                                            //setDettagliOrdine(dati);
                                            //setAggiornaTabDett(aggiornaTabDett + 1);
                                            //return;
                                        }
                                    })
                                }
                                else {
                                    datiDelete.push(riga)
                                }
                            })

                            setDettagliOrdine(datiDelete);
                            setAggiornaTabDett(aggiornaTabDett + 1);
                            return;
                        } else if (statoAttuale !== 2) {
                            notify({ position: "center", width: "auto", message: "L'ordine è stato confermato. Non è possibile effettuare ulteriori modifiche" }, "info", 4000);
                            return;
                        } else if (!autorizzazione.includes("M")) {
                            notify({ position: "center", width: "auto", message: "Non si dispone dell'autorizzazione necessaria per effettuare modifiche all'ordine" }, "info", 4000);
                            return;
                        }
                    }
                }
            }]
        }
    ]), [dettagliOrdine, aggiornaTabDett, statoAttuale, autorizzazione])

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "header") {
            let tooltip = "";
            switch (e.column.dataField) {
                case "quantitaOmaggio":
                    tooltip = "Quantità omaggio"
                    break;
                case "venduto":
                    tooltip = "Quantità venduto ultimo mese"
                    break;
                case "vendutoMedioMensile":
                    tooltip = "Venduto medio ultimi 12 mesi"
                    break;
                case "totGiacenza":
                    tooltip = "Giacenza totale gruppo"

                    e.cellElement.addEventListener('mouseover', function (arg: any) {
                        const headerTitle = e.cellElement.querySelector('.dx-datagrid-text-content');
                        headerTitle.setAttribute("title", " ");
                        if (headerTitle.scrollWidth > headerTitle.clientWidth) {
                            headerTitle.setAttribute("title", tooltip);
                        }
                    });

                    break;
                case "mesiCopertura":
                    tooltip = "Mesi di copertura"

                    e.cellElement.addEventListener('mouseover', function (arg : any) {
                        const headerTitle = e.cellElement.querySelector('.dx-datagrid-text-content');
                        headerTitle.setAttribute("title", " ");
                        if (headerTitle.scrollWidth > headerTitle.clientWidth) {
                            headerTitle.setAttribute("title", tooltip);
                        }
                    });

                    break;
                case "quantitaApprovata":
                    tooltip = "Quantita approvata"
                    break;
                case "quantitaOverstockGruppo":
                    tooltip = "Quantita overstock di gruppo"
                    break;
                
            }
            e.cellElement.title = tooltip;
            //console.log(e.cellElement.title)
        }
        if (e.rowType === "data" && (e.column.dataField === "quantita" ||
            e.column.dataField === "quantitaOmaggio" || e.column.dataField === "costo" ||
            e.column.dataField === "sconto1" || e.column.dataField === "sconto2" || e.column.dataField === "sconto3")) {
            e.cellElement.style.backgroundColor = "paleturquoise";
        }

        if (e.rowType === "data" && (e.column.dataField === "mesiCopertura")) {
            if (Number(e.data.mesiCopertura > 2))
                e.cellElement.style.backgroundColor = "#F7FFAB";
        }

        if (e.rowType === "data" && e.column && e.column.caption && e.column.dataField === "quantitaOverstockGruppo") {
            let condA = e.data.quantita + e.data.quantitaOmaggio - e.data.fabbisogno
            let condAValue = condA <= 6 ? 'vero' : 'falso'
            let condBValue = e.data.quantitaOverstockGruppo < e.data.fabbisogno ? 'vero' : 'falso'
            let tooltip = "";
            tooltip = 'Totale giacenza gruppo = ' + e.data.totGiacenza + '\r\n'+
                'Venduto di gruppo ultimi ' + e.data.giorniCoperturaClassificazione + ' giorni = ' + e.data.vendutoGruppoGiorniCoperturaClassificazione + '\r\n' +
                'Venduto farmacia ultimi ' + e.data.giorniCoperturaClassificazione + ' giorni = ' + e.data.vendutoGiorniCoperturaClassificazione + '\r\n' +
                'Fabbisogno farmacia = ' + e.data.fabbisogno + '\r\n' +
                'Qta overstock gruppo = ' + e.data.totGiacenza + ' - (' + e.data.vendutoGruppoGiorniCoperturaClassificazione + ' - ' + e.data.vendutoGiorniCoperturaClassificazione + ') = ' + e.data.quantitaOverstockGruppo + '\r\n' +
                'Qta in ordine accettata se: \r\n' +
                'Qta (qta + qta omaggio) – Fabbisogno <= 6 e Qta Overstock di gruppo < Fabbisogno farmacia\r\n' +
                'Qta in ordine - Fabbisogno <= 6 --> (' + e.data.quantita + ' + ' + e.data.quantitaOmaggio + ') - ' + e.data.fabbisogno + ' = ' + condA + ' <= 6 -->' + condAValue + ' \r\n' +
                'Qta Overstock di gruppo < Fabbisogno farmacia --> ' + e.data.quantitaOverstockGruppo + ' < ' + e.data.fabbisogno + ' --> ' + condBValue
            //e.cellElement.title = tooltip;
            //e.cellElement.setAttribute("data-html", "true")

            e.cellElement.addEventListener('mouseover', function (arg: any) {
                
                e.cellElement.setAttribute("title", tooltip);
                e.cellElement.setAttribute("data-html", "true")
            });

            
        }


        //(“Ordine RDA di pz. Ref. XYZ” – “Fabbisogno” <= 6) AND (“Overstock di gruppo” < “Fabbisogno FC01”) --> Quantità in RDA accettata.

        if (e.rowType === "data" && (e.column.dataField === "quantitaOverstockGruppo")) {
            //console.log(e.data)
            //console.log((Number(e.data.quantita) - Number(e.data.fabbisogno)))
            //console.log(((Number(e.data.quantitaOverstockGruppo)) < Number(e.data.fabbisogno)))
            if ((Number(e.data.quantita + e.data.quantitaOmaggio) - Number(e.data.fabbisogno)) <= 6 &&
                ((Number(e.data.quantitaOverstockGruppo)) < Number(e.data.fabbisogno))
            )
            {

            }
            else
                e.cellElement.style.backgroundColor = "#f7a539";
                
        }

    }, [])

    const onCellClick = (e: any) => {
        //console.log("cellClick")
        //console.log(e)
        //console.log(autorizzazione)
        if (openDrawer === true) setOpenDrawer(false);
        //if (e.row && e.row.data)
        //    setSelectedRowKeys([e.row.data]);
        if (e.rowType === "data" && (e.column.dataField !== "quantita" || e.column.dataField !== "quantitaOmaggio" ||
            e.column.dataField === "sconto1" || e.column.dataField === "sconto2" || e.column.dataField === "sconto3" ||
            e.column.dataField !== "costo")) {
            ricercaGiacenze(e.row.data.idFar, e.row.data.codCsf, e.row.data.prodotto, e.row.data.idRdaOrdineDettaglio);
        }
    }

    //useEffect(() => {
    //    console.log("effect autorizzazione")
    //    console.log(autorizzazione)
    //}, [autorizzazione])

    const onFocusedCellChanged = (e: any) => {
        //console.log("onFocusedCellChanged")
        //console.log(e)
        //console.log(autorizzazione)
        //console.log(allowEditing)
        if (tabellaInstance && (e.column.dataField === "quantita" || e.column.dataField === "quantitaOmaggio" ||
            e.column.dataField === "sconto1" || e.column.dataField === "sconto2" || e.column.dataField === "sconto3" ||
                e.column.dataField === "costo")) {
            //console.log("A")
            if (e.row) {
                //console.log("B")
                if (allowEditing) {
                    //console.log("C")
                    ricercaGiacenze(e.row.data.idFar, e.row.data.codCsf, e.row.data.prodotto, e.row.data.idRdaOrdineDettaglio);
                    //tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
                    tabellaInstance.instance.editCell(e.rowIndex, e.column.dataField);
                } else {
                    //console.log("D")
                    ricercaGiacenze(e.row.data.idFar, e.row.data.codCsf, e.row.data.prodotto, e.row.data.idRdaOrdineDettaglio);
                    //tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
                }
            }

        }
        else {
            //mi spostando tra le righe
            //console.log("E")
            if (e.row) {
                ricercaGiacenze(e.row.data.idFar, e.row.data.codCsf, e.row.data.prodotto, e.row.data.idRdaOrdineDettaglio);
                //tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
            }
            
        }
    }


    const calculateCustomSummary = (options: any) => {
        if (options.name == "TotalePrezzo") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    //                    options.totalValue = options.totalValue + (options.value.prezzoListino * options.value.quantita)
                    options.totalValue = options.totalValue + (options.value.prezzoListino * (options.value.quantita - options.value.quantitaOmaggio))
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "TotaleCosto") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    //options.totalValue = options.totalValue + (options.value.costo * options.value.quantita)
                    options.totalValue = options.totalValue + (options.value.costo * (options.value.quantita - options.value.quantitaOmaggio))
                    break;
                case "finalize":
                    break;
            }
        }
    }

    const propsTabDettagliOrdine: TabellaCompletaInterface = useMemo(() => ({
        id: "dettaglio_ordine_rda_" + idOrdine,
        dataSource: dettagliOrdine,
        selection: "multiple",
        autoWidth: true,
        height: 544,
        headerFilterVisible: false,
        defaultFilterValue: null,
        pagination: 10,
        rowAlternationEnabled: false,
        summary: {
            calculateCustomSummary: calculateCustomSummary,
            totalItems: [{
                name: "TotaleProdotti",
                showInColumn: "Prodotto",
                displayFormat: "Prodotti: {0}",
                summaryType: "count"
            },
                {
                name: "TotaleQuantita",
                showInColumn: "Qta",
                column: "quantita" ,
                    displayFormat: "Tot.: {0}",
                summaryType: "sum"
                },
                {
                    name: "TotaleQuantitaOmaggio",
                    showInColumn: "Qta Om",
                    column: "quantitaOmaggio",
                    displayFormat: "Tot.: {0}",
                    summaryType: "sum"
                },
                {
                    name: "TotalePrezzo",
                    showInColumn: "Prz(€)",
                    displayFormat: "{0}",
                    summaryType: "custom",
                    valueFormat: "#,##0.##",
                },
                {
                    name: "TotaleCosto",
                    showInColumn: "Costo(€)",
                    displayFormat: "{0}",
                    summaryType: "custom",
                    valueFormat: "#,##0.##",
                }
            ],
        },
        export: {
            enabled: true,
            fileName: "OrdineRDA_" + testata?.codiceOrdine
        },
        editing: {
            mode: "cell",
            allowUpdating: allowEditing,
            selectTextOnEditStart: true,
        }
    }), [dettagliOrdine, allowEditing, aggiornaTabDett])

    const onRowUpdating = (e: any) => {

        if (e.newData.quantitaOmaggio != null && (e.newData.quantitaOmaggio > e.oldData.quantita)) {

            alert("<i>La modifica verrà annullata perchè il valore digitato è superiore alla quantità ordinata al relativo fornitore.</i>", "Attenzione");
            
            e.cancel = true
            e.component.cancelEditData();
            
            return;

            
        }

        if (e.newData.quantita != null && (e.newData.quantita < e.oldData.quantitaOmaggio)) {

            alert("<i>La modifica verrà annullata perchè il valore digitato è inferiore alla quantità omaggio ordinata al relativo fornitore.</i>", "Attenzione");

            e.cancel = true
            e.component.cancelEditData();

            return;


        }

        //console.log("e")
        //console.log(e)
        const param = {
            idRdaOrdineDettaglio: e.key.idRdaOrdineDettaglio,
            idRdaOrdine: e.key.idRdaOrdine,
            prezzoListino: e.key.prezzoListino,
            iva: e.key.iva,
            costo: e.key.costo,
            sconto1: e.key.sconto1,
            sconto2: e.key.sconto2,
            sconto3: e.key.sconto3
            //sconto1: e.newData.sconto1 && e.newData.sconto1 >= 0 ? e.key.sconto1 : e.oldData.sconto1,
            //sconto2: e.newData.sconto2 && e.newData.sconto2 >= 0 ?  e.key.sconto2 : e.oldData.sconto2,
            //sconto3: e.newData.sconto3 && e.newData.sconto3 >= 0 ? e.key.sconto3 : e.oldData.sconto3
        }

        if (e.newData.sconto1 >= 0)
            param.sconto1 = e.newData.sconto1
        else
            param.sconto1 = e.oldData.sconto1

        if (e.newData.sconto2 >= 0) {
            //console.log("asdasdad")
            //console.log(e.key.sconto2)
            //console.log(e.newData.sconto2)
            param.sconto2 = e.newData.sconto2
        }

        else {
            //console.log("dddddddddd")
            param.sconto2 = e.oldData.sconto2
        }
            


        if (e.newData.sconto3 >= 0)
            param.sconto3 = e.newData.sconto3
        else
            param.sconto3 = e.oldData.sconto3

        //console.log(param)


        if (e.newData.quantita === null) {
            e.newData.quantita = 0;
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
            return;
        }
        if (e.newData.quantitaOmaggio === null) {
            e.newData.quantitaOmaggio = 0;
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
            return;
        }
        if (e.newData.costo === null) {
            e.newData.costo = 0;
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
            return;
        }
        if (e.newData.sconto1 === null) {
            e.newData.sconto1 = 0;
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
            return;
        }
        if (e.newData.sconto2 === null) {
            e.newData.sconto2 = 0;
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
            return;
        }
        if (e.newData.sconto3 === null) {
            e.newData.sconto3 = 0;
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
            return;
        }
        if (e.newData.quantita < 0 || e.newData.quantitaOmaggio < 0 ||
            e.newData.costo < 0 || e.newData.sconto1 < 0 || e.newData.sconto2 < 0 || e.newData.sconto3 < 0) {
            e.cancel = true;
            notify({ position: "center", width: "auto", message: "Non è consentito inserire valori negativi" }, "error", 4000)
            return;
        } else if (e.newData.quantita > 0 || e.newData.quantita === 0 || e.newData.quantitaOmaggio > 0 ||
            e.newData.quantitaOmaggio === 0 || e.newData.costo > 0 || e.newData.costo === 0 ||
            e.newData.sconto1 > 0 || e.newData.sconto1 === 0 ||
            e.newData.sconto2 > 0 || e.newData.sconto2 === 0 ||
            e.newData.sconto3 > 0 || e.newData.sconto3 === 0) {
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
        }
    }

    const aggiornaQuantita = (updateRow: OggettoInterface, e: any, param: OggettoInterface) => {

        //console.log("aggiornaQuantita")
        //console.log(updateRow)
        //console.log(e)

        if (statoAttuale && autorizzazione) {
            if ((statoAttuale === 2 && autorizzazione.includes("M"))
            || statoAttuale === 1 ) {
                if (e.newData.quantita === 0 || e.newData.quantita > 0 ||
                    e.newData.quantitaOmaggio === 0 || e.newData.quantitaOmaggio > 0) {
                    RestServices().rest('POST', updateRow, urlOrdiniRda.setDettagliOrdine).then(result => {
                        if (!result.idRdaOrdineDettaglio) {
                            e.cancel = true;
                            notify({ position: "center", width: "auto", message: "Non è stato possibile modificare il campo desiderato. Si prega di riprovare" }, "error", 4000)
                        }
                        else {

                            const copiaDati = dettagliOrdine;
                            copiaDati.forEach(riga => {
                                if (riga.idRdaOrdineDettaglio === e.key.idRdaOrdineDettaglio) {
                                    // mesi copertura =  (giacenza + quantita ordinata) / venduto medio

                                    let vendutoMedio = riga.vendutoMedioMensile != null ? riga.vendutoMedioMensile : 0;
                                    let quantita = riga.quantita != null ? riga.quantita : 0;
                                    let quantitaOmaggio = riga.quantitaOmaggio != null ? riga.quantitaOmaggio : 0;
                                    let giacenza = riga.giacenza != null ? riga.giacenza : 0
                                    let quantitaApprovata = riga.quantitaApprovata != null ? riga.quantitaApprovata : 0

                                    if (vendutoMedio == 0)
                                        riga.mesiCopertura = null

                                    if ((quantita + giacenza) == 0)
                                        riga.mesiCopertura = null

                                    if (vendutoMedio > 0 && ((quantita + giacenza + quantitaApprovata + quantitaOmaggio) > 0))
                                        riga.mesiCopertura = (quantita + giacenza + quantitaApprovata + quantitaOmaggio) / vendutoMedio


                                    setDettagliOrdine(copiaDati);
                                    setAggiornaTabDett(aggiornaTabDett + 1);
                                   // ricalcolaSconto()
                                }
                            })

                        }
                    })
                } else if (e.newData.costo === 0 || e.newData.costo > 0) {
                    const paramCosto = { ...param, costo: e.newData.costo }
                    RestServices().rest('POST', paramCosto, urlOrdiniRda.updateCosto).then(result => {
                        if (typeof result === "number") {
                            const copiaDati = dettagliOrdine;
                            copiaDati.forEach(riga => {
                                if (riga.idRdaOrdineDettaglio === e.key.idRdaOrdineDettaglio) {
                                    riga.sconto1 = result;
                                    riga.sconto2 = 0;
                                    riga.sconto3 = 0;
                                }
                            })
                            setDettagliOrdine(copiaDati);
                            setAggiornaTabDett(aggiornaTabDett + 1);
                            //ricalcolaSconto()
                        } else {
                            e.cancel = true;
                            notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento dello sconto1, si prega di riprovare" }, "error", 4000)
                        }
                    })
                } else if (e.newData.sconto1 === 0 || e.newData.sconto1 > 0 ||
                    e.newData.sconto2 === 0 || e.newData.sconto2 > 0 ||
                    e.newData.sconto3 === 0 || e.newData.sconto3 > 0) {
                    if (e.newData.sconto1 > 100) {
                        e.cancel = true;
                        notify({ position: "center", width: "auto", message: "Il valore dello sconto non può essere superiore a 100" }, "error", 4000);
                        return;
                    }
                    if (e.newData.sconto2 > 100) {
                        e.cancel = true;
                        notify({ position: "center", width: "auto", message: "Il valore dello sconto non può essere superiore a 100" }, "error", 4000);
                        return;
                    }
                    if (e.newData.sconto3 > 100) {
                        e.cancel = true;
                        notify({ position: "center", width: "auto", message: "Il valore dello sconto non può essere superiore a 100" }, "error", 4000);
                        return;
                    }

                    const paramSconto1 = {
                        ...param,
                        sconto1: e.newData.sconto1 >= 0 ? e.newData.sconto1 : e.oldData.sconto1,
                        sconto2: e.newData.sconto2 >= 0 ? e.newData.sconto2 : e.oldData.sconto2,
                        sconto3: e.newData.sconto3 >= 0 ? e.newData.sconto3 : e.oldData.sconto3
                    }

                    ////lo sconto deve essere sempre in valore netto % prima di passarlo al servizio di update

                    //if (selectedIndex == 1) {
                    //   //ricalcolare sconto netto % per tutti gli sconti
                    //}

                    RestServices().rest('POST', paramSconto1, urlOrdiniRda.updateSconto1).then(result => {
                        if (typeof result === "number") {
                            const copiaDati = dettagliOrdine;
                            copiaDati.forEach(riga => {
                                if (riga.idRdaOrdineDettaglio === e.key.idRdaOrdineDettaglio) {
                                    riga.costo = result;
                                }
                            })
                            setDettagliOrdine(copiaDati);
                            setAggiornaTabDett(aggiornaTabDett + 1);
                        } else {
                            e.cancel = true;
                            notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                        }
                    })
                }
            } else if (statoAttuale !== 2) {
                e.cancel = true;
                notify({ position: "center", width: "auto", message: "L'ordine è stato confermato. Non è possibile effettuare ulteriori modifiche" }, "info", 4000);
                return;
            } else if (!autorizzazione.includes("M")) {
                e.cancel = true;
                notify({ position: "center", width: "auto", message: "Non si dispone dell'autorizzazione necessaria per effettuare modifiche all'ordine" }, "info", 4000);
                return;
            }
        }
    }

    const aggiornaQuantitaTrasf = (updateRow: OggettoInterface, e: any, param: OggettoInterface) => {
        if (statoAttuale && autorizzazione) { 
            if ((statoAttuale === 2 && autorizzazione.includes("M")) || statoAttuale === 1) {
                if (e.newData.qtaFarTrasf === 0 || e.newData.qtaFarTrasf > 0) {
                    RestServices().rest('POST', param, urlOrdiniRda.setQuantitaTrasf).then(result => {
                        if (result) {
                            if (!result.esito) {
                                e.cancel = true;
                                notify({ position: "center", width: "auto", message: "Non è stato possibile modificare il campo desiderato. Si prega di riprovare" }, "error", 4000)
                            }
                        } else {
                            e.cancel = true;
                            notify({ position: "center", width: "auto", message: "Non è stato possibile modificare il campo desiderato. Si prega di riprovare" }, "error", 4000)
                        }
                        
                    })
                }
            } else if (statoAttuale !== 2) {
                e.cancel = true;
                notify({ position: "center", width: "auto", message: "L'ordine è stato confermato. Non è possibile effettuare ulteriori modifiche" }, "info", 4000);
                return;
            } else if (!autorizzazione.includes("M")) {
                e.cancel = true;
                notify({ position: "center", width: "auto", message: "Non si dispone dell'autorizzazione necessaria per effettuare modifiche all'ordine" }, "info", 4000);
                return;
            }
        }
    }

    //const propsTabGiacenzeAltreFarm: TabellaSempliceInterface = useMemo(() => ({
    //    id: "ordineRdaGiacenzeAltreFarm",
    //    height: 470,
    //    dataSource: giacenzeVenduto,
    //    filterRow: true,
    //    editing: {
    //        mode: "cell",
    //        allowUpdating: true,
    //        selectTextOnEditStart: true,
    //    }
    //}), [giacenzeVenduto])

    const propsTabGiacenzeAltreFarm: TabellaCompletaInterface = useMemo(() => ({
        id: "ordineRdaGiacenzeAltreFarm",
        height: 250,
        selection: 'none',
        headerFilterVisible:false,
        dataSource: giacenzeVenduto,
        editing: {
            mode: "cell",
            allowUpdating: true,
            selectTextOnEditStart: true,
        }
    }), [giacenzeVenduto])

    const columnsGiacenze = useMemo(() => CreaColonne([
        { caption: "Farmacie", dataField: "descrFarm", allowEditing: false, width: 60 },
        { caption: "Giac Vend", dataField: "giacenzaVendibili", sortOrder: "desc", allowEditing: false, width: 63 },
        { caption: "Giac Inv", dataField: "giacenzaInvendibili", visible: false, allowEditing: false },
        { caption: "Ven.U.M.", dataField: "vendutoMensile", allowEditing: false, width: 50 },
        { caption: "Ven.Med.", dataField: "vendutoAnnuoMedio", allowEditing: false, width: 50, format: { precision: 2, type: "fixedPoint" } },
        { caption: "Mesi copert.", dataField: "mesiCopertura", allowEditing: false, width: 50, format: { precision: 2, type: "fixedPoint" } },
        { caption: "Qta. Trasf.", dataField: "qtaFarTrasf", allowEditing: true, width: 60, },
        { caption: "Qta. Rich. Trasf.", dataField: "qtaFarTrasfRichiesta", allowEditing: false, width: 60 }
    ]), [])

    const onCellGiacenzePrepared = useCallback((e: any) => {
        if (e.rowType === "header") {
            let tooltip = "";
            
            switch (e.column.dataField) {
                case "giacenzaVendibili":
                    tooltip = "Giacenze Vendibili"
                    break;
                case "giacenzaInvendibili":
                    tooltip = "Giacenze Invendibili"
                    break;
                case "vendutoMensile":
                    tooltip = "Venduto Ultimo Mese"
                    break;
                case "vendutoAnnuoMedio":
                    tooltip = "Venduto Medio Ultimi 12 Mesi"
                    break;
                case "qtaFarTrasf":
                    tooltip = "Quantita da trasferire"
                    break;
                case "qtaFarTrasfRichiesta":
                    tooltip = "Quantita già richiesta da trasferire"
                    break;
                case "mesiCopertura":
                    tooltip = "Mesi di copertura"

                    e.cellElement.addEventListener('mouseover', function (arg: any) {
                        const headerTitle = e.cellElement.querySelector('.dx-datagrid-text-content');
                        headerTitle.setAttribute("title", " ");
                        if (headerTitle.scrollWidth > headerTitle.clientWidth) {
                            headerTitle.setAttribute("title", tooltip);
                        }
                    });

                    break;
                case "descrFarm":
                    tooltip = "Altre farmacie"
                    break;

            }
            e.cellElement.title = tooltip;    
        }

        if (e.rowType === "data" && (e.column.dataField === "qtaFarTrasf")) {
            e.cellElement.style.backgroundColor = "paleturquoise";

        }

        if (e.rowType === "data" && (e.column.dataField === "mesiCopertura")) {
            if (Number(e.data.mesiCopertura > 2))
                e.cellElement.style.backgroundColor = "#F7FFAB";
        }

        if (e.rowType === "data" && e.column && e.column.caption && e.column.dataField === "qtaFarTrasfRichiesta") {
            //console.log(e)
            let tooltip = "";
            tooltip = e.data.tooltip 

            e.cellElement.addEventListener('mouseover', function (arg: any) {

            e.cellElement.setAttribute("title", tooltip);
            e.cellElement.setAttribute("data-html", "true")
            });


        }

    }, [])

    const onRowUpdatingGiac = (e: any) => {

        //console.log(e)
        //console.log(e.key)
        //console.log(e.key.qtaFarTrasf)
        //console.log(e.newData.qtaFarTrasf)

        let param = {
            idRdaOrdineDettaglio: e.key.idRdaDettaglio,
            idFar: e.key.idFar,
            qta: e.newData.qtaFarTrasf
        }

        if (e.newData.qtaFarTrasf === null) {
            e.newData.quantita = 0;
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantitaTrasf(updateRow, e, param);
            return;
        }
        
        if (e.newData.qtaFarTrasf < 0 ) {
            e.cancel = true;
            notify({ position: "center", width: "auto", message: "Non è consentito inserire valori negativi" }, "error", 4000)
            return;
        } else if (e.newData.qtaFarTrasf >= 0 ) {
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantitaTrasf(updateRow, e, param);
        }
    }


    const onRowPrepared = useCallback((e: any) => {
        if (e.values) {
            if (e.rowType === "data" && e.rowType != 'header') {
                let costo :number;
                let costoListino:number;

                costo = +e.data.costo
                costoListino = +e.data.costoListino

                if (costoListino === undefined && costo >= 0) {
                    e.rowElement.style.backgroundColor = '#EFB2A5';
                }
                else if (Number.isNaN(costoListino) && costo >= 0) {
                    e.rowElement.style.backgroundColor = '#EFB2A5';
                }
                else if ((parseFloat(costo.toFixed(2)) > parseFloat(costoListino.toFixed(2)))) {
                    e.rowElement.style.backgroundColor = '#EFB2A5';
                }
                else if (parseFloat(costo.toFixed(2)) < parseFloat(costoListino.toFixed(2))) {
                    e.rowElement.style.backgroundColor = '#D1FACA';
                }
            }
        }
    }, [])

    const startRicercaProdInBD = () => {
        if (statoAttuale && autorizzazione) {
            if (((statoAttuale === 2 && autorizzazione.includes("M")) || statoAttuale === 1) && inputCerca.length > 0) {
                setIsVisible(true);
                const parametriCerca = {
                    testo: inputCerca,
                    lstFar: farmSelez,
                    soloInCommercio: false,
                    soloInGiacenza: false
                }
                RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                    .then(result => {
                        setIsVisible(false);
                        if (result.length === 1) {
                            if (result[0].codCsf) {
                                insertProdotto(result[0].codCsf);
                            } else {
                                notify({ position: "center", width: "auto", message: "Oops, si è verificato un problema. Ci scusiamo per il disagio, si prega di riprovare" }, "error", 4000)
                            }
                        } else if (result.length > 1) {
                            setCercaNuovoProd(result);
                            handleOpen();
                        } else {
                            notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                            setCercaNuovoProd([]);
                        }
                    })
            } else if (statoAttuale !== 2) {
                setInputCerca("");
                notify({ position: "center", width: "auto", message: "L'ordine è stato confermato. Non è possibile effettuare ulteriori modifiche" }, "info", 4000);
                return;
            } else if (!autorizzazione.includes("M")) {
                setInputCerca("");
                notify({ position: "center", width: "auto", message: "Non si dispone dell'autorizzazione necessaria per effettuare modifiche all'ordine" }, "info", 4000);
                return;
            }
        }
    }

    const oggettoRiga = useMemo(() => ({
        idFar: null,
        idRdaOrdineDettaglio: null,
        idRdaOrdine: idOrdine,
        codCsf: null,
        prodotto: null,
        minsan: null,
        quantita: null,
        quantitaOmaggio: null,
        iva: null,
        costo: null,
        sconto1: null,
        sconto2: null,
        sconto3: null,
        giacenza: null,
        totGiacenza: null,
        ean: null,
        prezzoListino: null
    }), [])

    let nuovoDettaglio: DettagliOrdineInterface[] = [];

    const insertProdottoLight = async (codCsf: number) => {
        if (!codCsf) return;
        if (farmSelez.length > 0) {
            const paramInsert = { ...oggettoRiga, idRdaOrdine: parseInt(idOrdine), idFar: farmSelez[0], codCsf: codCsf };

            try {
                const result = await RestServices().rest('POST', paramInsert, urlOrdiniRda.setDettagliOrdine);

                if (result) {
                    if (result === true) {
                        // Gestione del risultato vero
                    } else if (result.idRdaOrdineDettaglio) {
                        // Gestione del risultato con idRdaOrdineDettaglio
                    } else {
                        // Gestione di altri risultati
                    }
                } else if (result && result.message && result.severity) {
                    // Gestione del messaggio e della severità
                } else {
                    // Gestione di altri casi
                }
            } catch (error) {
                console.error('Errore durante l’inserimento del prodotto:', error);
            }
        }
    };

    const insertProdotto = (codCsf: number) => {
        if (!codCsf) return;
        if (farmSelez.length > 0) {
            const paramInsert = { ...oggettoRiga, idRdaOrdine: parseInt(idOrdine), idFar: farmSelez[0], codCsf: codCsf }
            RestServices().rest('POST', paramInsert, urlOrdiniRda.setDettagliOrdine).then(result => {
                if (result) {
                    //console.log(result.idRdaOrdineDettaglio)
                    if (result === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                    } else if (result.idRdaOrdineDettaglio) {
                        nuovoDettaglio = dettagliOrdine;
                        nuovoDettaglio.unshift(result);
                        setDettagliOrdine(nuovoDettaglio);
                        setAggiornaTabDett(aggiornaTabDett + 1);
                        setInputCerca("");
                        setSelectedRowKeys([result]);
                    } else {
                        notify({ position: "center", width: "auto", message: "Il prodotto cercato si trova già all'interno dell'ordine" }, "info", 4000)
                    }
                } else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                } else {
                    notify({ position: "center", width: "auto", message: "Oops, c'è stato un problema. Ci scusiamo per il disagio" }, "error", 4000)
                }
            })
        }
    }

    const templateFiltri = () => {
        return (
            <Row id="testata">
                <TestataOrdineRda
                    testata={testata}
                    farmacie={props.datiFarmacie}
                    statoAttuale={statoAttuale}
                    setStatoAttuale={setStatoAttuale}
                    listaPagamenti={listaPagamenti}
                    listaFornitori={listaFornitori}
                    setIdTipPagamento={setIdTipPagamento}
                    autorizzazioni={autorizzazione }
                />
            </Row>
        )
    }

    const dettagliRighe = (e: any) => {
        //if (e.data.idFar == 0) return;
        const farmacia = e.data.idFar;
        setAltraFarmSelez([farmacia]);
        setRigaTabSemplice([e.data]);
    }

    const allineaCostoClick = (e: any) => {
        if (selectedRowKeys && selectedRowKeys.length > 0) {
            //setMessaggioConferma("Saranno aggiornati i costi dei prodotti selezionati con i rispettivi costi di listino. Continuare?");
            let a = "ai"
            let p = "prodotti"
            if (selectedRowKeys.length == 1) {
                a = "a"
                p = "prodotto"
            }
            setMessaggioConferma("Si sta per allineare il costo " + a + " " + selectedRowKeys.length + " " + p + " dell'ordine con i rispettivi costi di listino. Continuare?");
            setDialogConfermaIsOpen(true);
        }
        else {
            //notify({ position: "center", width: "auto", message: "Selezionare almeno un prodotto" }, "error", 4000)

            let a = "ai"
            let p = "prodotti"
            if (dettagliOrdine && dettagliOrdine.length > 1) {
                a = "ai"
                p = "prodotti"
            }
            if (dettagliOrdine && dettagliOrdine.length == 1) {
                a = "a"
                p = "prodotto"
            }

            setMessaggioConferma("Si sta per allineare il costo " + a + " " + dettagliOrdine.length + " " + p + " dell'ordine con i rispettivi costi di listino. Continuare?");
            setDialogConfermaIsOpen(true);
        }
        
    }

    const functionNext = () => {
        setDialogConfermaIsOpen(false);
        allineaCosto();
    }

    const allineaCosto = () => {

        let prodotti :any[] = []
        if (selectedRowKeys && selectedRowKeys.length > 0)
            prodotti = selectedRowKeys
        else
            prodotti = dettagliOrdine

        if (prodotti && prodotti.length > 0) {

            setIsVisible(true);
            RestServices().rest('POST', prodotti, urlOrdiniRda.allineaCosto)
                .then(result => {
                    if (result) {
                        if (result.esito == true) {
                            notify({ position: "center", width: "auto", message: "Allineamento costi eseguito con successo" }, "success", 4000);
                            RestServices().rest('POST', idOrdine, urlOrdiniRda.getDettagliOrdine)
                                .then(result => {
                                    setIsVisible(false);
                                    if (result && result.testata && result.lstRdaDettaglio) {
                                        setDettagliOrdine(result.lstRdaDettaglio);
                                    } else {
                                        notify({ position: "center", width: "auto", message: "Oops, si è verificato un problema. Ci scusiamo per il disagio" }, "error", 4000)
                                    }
                                    setIsVisible(false);
                                })
                            
                        }
                    } else if (result.esito == false) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "info", 4000)
                    } else {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Errore imprevisto. Ci scusiamo per il disagio" }, "info", 4000)
                    }
                })
        }
    }


    const colonneTabUltimiAcquisti = useMemo(() => CreaColonne([
        { caption: "Data DDT", dataField: "dataDDT", format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Num. DDT", dataField: "numeroDDT", width: 90 },
        { caption: "Fornitore", dataField: "ragioneSocialeFornitore", width: 120 },
        { caption: "Qta", dataField: "quantita",width: 40 },
        { caption: "Qta Omaggio", dataField: "quantitaOmaggio",width: 50 },
        { caption: "Costo", dataField: "costo", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Costo Reale", dataField: "costoReale", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Sct.", dataField: "scosto", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Sct. Reale", dataField: "scostoReale", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },

    ]), [])

    const handleCloseAggiungiProdottiDitta = () => {
        setIsDialogAggiungiProdottiDittaOpen(false);
        
    }

    const handleCloseDialogSconto = () => {
        setIsDialogScontoOpen(false);

    }

    useEffect(() => {
        

            if (isDialogAggiungiProdottiDittaOpen == false && listCodCsf && listCodCsf.length > 0) {

                 insertProdotti()

            }

    }, [isDialogAggiungiProdottiDittaOpen])

    const insertProdotti = async () => {

        setIsVisible(true)
        const promises = listCodCsf.map(async (item: number) => {
            await insertProdottoLight(item);
        });

        await Promise.all(promises);

        // Questo codice verrà eseguito solo dopo che tutte le chiamate a insertProdottoLight sono state completate
        setIsVisible(false)
        carica()
    };

    useEffect(() => {

        if (filtri.sconto)
            setDisabledSconto(false)
        else
            setDisabledSconto(true)

    }, [filtri.sconto])

    const applicaScontoProdotti = (sconto:number) => {

        if (!selectedRowKeys || selectedRowKeys == null || selectedRowKeys == undefined || selectedRowKeys.length == 0) {
            notify({ position: "center", width: "auto", message: "Selezionare almeno un prodotto" }, "error", 4000)
            return;
        }

        if (selectedRowKeys && sconto && sconto > 0) {
            let prodotti : any [] = []

            selectedRowKeys.forEach((pro: any) => {
                let item : any = {
                    idRdaOrdineDettaglio: pro.idRdaOrdineDettaglio,
                    prezzoListino: pro.prezzoListino,
                    iva: pro.iva,
                    sconto1: sconto,
                    sconto2: 0,
                    sconto3: 0
                }

                prodotti.push(item)
            })

            let param = {
                sconto: sconto,
                idOrdineRda: testata?.id,
                prodotti: prodotti
            }

            setIsVisible(true)
            RestServices().rest('POST', param, urlOrdiniRda.updateScontoProdotti).then(result => {
                if (typeof result === "boolean"  && result == true ) {
                    notify({ position: "center", width: "auto", message: "Sconto applicato" }, "succes", 4000)
                    setIsVisible(false)
                    carica()
                    setIsDialogScontoOpen(false)
                } else {
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento, si prega di riprovare" }, "error", 4000)
                    setIsVisible(false)
                }
            })
        }

    }

    //const priorities = ['Sc.to Netto', 'Sc.to Lordo'];

    //const  priorityEntities = [
    //    { id: 0, text: 'Sc.to Netto' },
    //    { id: 1, text: 'Sc.to Lordo' }
    //];
    
    //const onValueChanged = (e: any) => {
    //    groupBoxValueChanged(e.value)

    //}

    //const groupBoxValueChanged = (index: number) => {

    //    //console.log(index)
    //    setSelectedIndex(index)
    //}

    //useEffect(() => { 
    //    ricalcolaSconto()

    //}, [selectedIndex])

    const ricalcolaSconto = () => {
        //console.log("ciao")
        const copiaDati = dettagliOrdine;
        copiaDati.forEach(riga => {
            //calcola sconto lordo e netto (%) a partire dal costo e prezzo
            let prezzo: number | null = riga.prezzoListino
            let costo: number | null = riga.costo
            let iva: number | null = riga.iva

            //console.log(riga)

            if (prezzo != null && iva != null && costo != null) {
                let prezzoNetto: number | null = 0
                let prezzoLordo = prezzo
                //il prezzo in griglia è lordo

                prezzoNetto = (prezzoLordo * 100) / (100 + iva)


                let scontoNetto = ((prezzoNetto - costo) / prezzoNetto) * 100
                let scontoLordo = ((prezzoLordo - costo) / prezzoLordo) * 100


                //console.log(scontoNetto)
                //console.log(scontoLordo)

                if (scontoNetto < 0)
                    scontoNetto = 0

                if (scontoLordo < 0)
                    scontoLordo = 0

                if (selectedIndex == 0) {
                    //netto
                    riga.sconto1 = Number(scontoNetto.toFixed(2))
                }
                else {
                    //lordo
                    riga.sconto1 = Number(scontoLordo.toFixed(2))
                }
            }


            //

        })

        //console.log(copiaDati)
        setDettagliOrdine(copiaDati);
        setAggiornaTabDett(aggiornaTabDett + 1);
    }

    return (
        <>
            <DialogConferma
                dialogIsOpen={dialogConfermaIsOpen}
                message={messaggioConferma}
                functionNext={functionNext}
                handleCloseConferma={handleCloseConferma}
            />
            {loadPanel}
            {auth &&
                <>
                    <div className="contenutoPagina">
                        <DrawerFiltri
                        id="testataOrdineRda"
                        templateFiltri={templateFiltri}
                        testoDrawer="Dettagli Ordine RDA"
                        open={openDrawer}
                        setOpen={setOpenDrawer}
                        testoAvviso={(testata?.idTipPagamentoFornitore != idTipPagamento) ? 'Tipo di pagamento impostato diverso da quello di default del fornitore. Valore di default: ' + ((testata?.descTipPagamentoFornitore == undefined) ? 'non definito' : testata?.descTipPagamentoFornitore) : ''}
                    />
                        <Row id="rigaRicerca">
                            <Col sm='1' className="ordineRda">
                                <span>Ricerca prodotto</span>
                            </Col>
                            <Col sm='2' className="boxCerca">
                            <BoxTestoCerca placeholder="codice, EAN, descrizione" id="ricercaProdottiBD" setInputCerca={setInputCerca} startRicerca={startRicercaProdInBD} inputCerca={inputCerca} />
                        </Col>
                        <Col sm="1">
                            <Button variant="info"
                                id="btnApplicaSconto"
                                title="Applica sconto ai prodotti"
                                onClick={() => {
                                    if (!selectedRowKeys || selectedRowKeys.length == 0) {
                                        
                                        notify({ position: "center", width: "auto", message: "Selezionare almeno un prodotto" }, "error", 4000);
                                    }
                                    else
                                        setIsDialogScontoOpen(true)
                                }}
                            >
                                Sconto
                            </Button>
                        </Col>
                        {/*<Col sm='2'>
   
                            <RadioGroup
                                items={priorityEntities}
                                defaultValue={priorityEntities[0].id}
                                layout="horizontal"
                                onValueChanged={onValueChanged}
                                valueExpr="id" displayExpr="text"
                                value={selectedIndex}
                            />
                        </Col>*/}
                        <Col sm='6' className="colBtnStampa">

                            <Button variant="warning" id="btnAggiungiRda" title="Aggiungi prodotti" onClick={() => setIsDialogAggiungiProdottiDittaOpen(true)}>
                                <FontAwesomeIcon icon={faPlus} className="pdfFileIcon" />
                                Aggiungi Prodotti
                            </Button>
                            <Button variant="success" id="btnDiffRda" title="Differenze con l'originale" onClick={() => setIsDialogDiffOpen(true)}>
                                <FontAwesomeIcon icon={faPaste} className="pdfFileIcon" />
                                    Differenze
                                </Button>
                            <Button id="btnStampaPdfOrdine" variant="primary" onClick={() => window.open(urlOrdiniRda.stampaPdf + idOrdine)}>
                                <FontAwesomeIcon icon={faFilePdf} className="pdfFileIcon" />
                                    Stampa PDF
                                </Button>
                            <Button id="btnAllineaCosto" variant="secondary" title="Allinea il costo al costo di listino(se presente)" onClick={allineaCostoClick}>
                                <FontAwesomeIcon icon={faClone} className="pdfFileIcon" />
                                    Allinea costo
                                </Button>
                            </Col>
                            <Col sm='2' className="spazioProdSelez">
                                {descrProdotto && <span id="descrProdSelez">{descrProdotto}</span>}
                            </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <div className="leftcontent">
                                <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colori" /> Legenda colori:</span>
                            &nbsp;
                            <span id="lblCostoMinore" title="Costo minore del costo di listino" className="lblCostoMinore">Costo minore del costo di listino</span>
                            &nbsp;
                             <span id="lblCostoMaggiore" title="Costo maggiore del costo di listino" className="lblCostoMaggiore">Costo maggiore del costo di listino</span>
                                &nbsp;
                                <span id="lblQuantitaInOrdine" title="Quantita' in ordine non accettata" className="lblQuantitaInOrdine">Quantita' in ordine non accettata</span>
                        </div>
                        </Col>
                    </Row>
                    {/* 
                    <div className="container-fluid">
                        <div className="row" id= "rigaGridDettagliRDA">
                            <div className="col" style={{paddingLeft:0} }>
                                {aggiornaTabDett &&
                                    <TabellaCompletaAlternate
                                        propsTabella={propsTabDettagliOrdine}
                                        onRowUpdating={onRowUpdating}
                                        setTabellaInstance={setTabellaInstance}
                                        selectedRowKeys={selectedRowKeys}
                                        columns={columnsDettaglio}
                                        onCellPrepared={onCellPrepared}
                                        onCellClick={onCellClick}
                                        onFocusedCellChanged={onFocusedCellChanged}
                                        onRowPrepared={onRowPrepared}
                                        rowAlternationEnabled={false}
                                    />}
                            </div>
                            <div className="col">
                                <TabellaSempliceEdit
                                    propsTabella={propsTabGiacenzeAltreFarm}
                                    columns={columnsGiacenze}
                                    onCellPrepared={onCellGiacenzePrepared}
                                    dettagliRighe={dettagliRighe}
                                    selectedRowKeys={rigaTabSemplice}
                                    onRowUpdating={onRowUpdatingGiac}
                                />
                            </div>
                        </div>
                    </div>
                    */ }
                    
                    <Row id="rigaGridDettagliRDA">
                        <Col sm='9' className="ordineRda">
                            {aggiornaTabDett &&
                                <TabellaCompletaAlternate
                                propsTabella={propsTabDettagliOrdine}
                                onRowUpdating={onRowUpdating}
                                setTabellaInstance={setTabellaInstance}
                                selectedRowKeys={selectedRowKeys}
                                columns={columnsDettaglio}
                                onCellPrepared={onCellPrepared}
                                onCellClick={onCellClick}
                                onFocusedCellChanged={onFocusedCellChanged}
                                onRowPrepared={onRowPrepared}
                                rowAlternationEnabled={false}
                                togliFilterPanel={true}
                                onSelectionChanged={(datiRow: any) => setSelectedRowKeys(datiRow.selectedRowKeys)}
                                    />}
                        </Col>
                        <Col sm='3'>
                            {autorizzazione && !autorizzazione.includes("O") && 

                                
                                <Col sm='12' className="ordineRdaGiac" id = "colGiacenzeRda">
                                    <TabellaSempliceEdit
                                        propsTabella={propsTabGiacenzeAltreFarm}
                                        columns={columnsGiacenze}
                                        onCellPrepared={onCellGiacenzePrepared}
                                        dettagliRighe={dettagliRighe}
                                        selectedRowKeys={rigaTabSemplice}
                                        onRowUpdating={onRowUpdatingGiac}
                                    />
                                    {loadPanelGiacenze}
                                </Col>
                        
                            }
                            <Col sm="12" className="ordineRdaGiac">
                                <div className="labelTabella" style={{ "backgroundColor": "rgb(150, 255, 164)", "color": "black", textAlign: 'center', marginTop:24 }}><b>Ultimi acquisti</b></div>
                                    <DataGrid
                                    id={'ultimiAcquisti'}
                                    showBorders={true}
                                    showRowLines={true}
                                    hoverStateEnabled={true}
                                    dataSource={tabellaUltimiAcquisti}
                                    height={250 }
                                    >
                                        {colonneTabUltimiAcquisti}
                                </DataGrid>
                            </Col>
                        </Col>
                    </Row>


                    <Row>&nbsp;</Row>
                    <table>
                        <tr>
                            <td>
                                <div className="rotated"><b>VENDUTO</b></div>
                            </td>
                            <td>
                                <Row id="gridVendutoRda" style={{ marginTop: -20 }}>
                                    <Col sm='6' id="colVendutoRda">
                                        <GrigliaVendutoRda
                                            codiceCsfSelez={codiceCsfSelez}
                                            farmSelez={farmSelez}
                                            setIsLoadVisible={setIsLoadVisible}
                                            listaFarmacie={props.datiFarmacie}
                                            id="vendutoFarmRda"
                                            acquistato={false}
                                            soloAcquistato={false}
                                        />
                                        {loadPanelVenduto}
                                    </Col>
                                    {autorizzazione && !autorizzazione.includes("O") &&

                                        <Col sm='6' id="colVendutoAltraFarmRda">
                                            <GrigliaVendutoRda
                                                codiceCsfSelez={codiceCsfSelez}
                                                farmSelez={altraFarmSelez}
                                                setIsLoadVisible={setIsLoadAltraFarmVisible}
                                                listaFarmacie={props.datiFarmacie}
                                                id="vendutoFarmRdaAltra"
                                                acquistato={false}
                                                soloAcquistato={false}
                                            />
                                            {loadPanelVendutoAltraFarm}
                                        </Col>

                                    }


                                </Row>
                            </td>
                        </tr>
                    </table>

                            

                    <Row>&nbsp;</Row>
                    <table>
                        <tr>
                            <td>
                                <div className="rotated"><b>ACQUISTATO</b></div>
                            </td>
                            <td>
                                <Row id="gridAcquistatoRda" style={{ marginTop: -20 }}>
                                    <Col sm='6' id="colAcquistatoRda">
                                        <GrigliaVendutoRda
                                            codiceCsfSelez={codiceCsfSelez}
                                            farmSelez={farmSelez}
                                            setIsLoadVisible={setIsLoadVisibleAcquistato}
                                            listaFarmacie={props.datiFarmacie}
                                            id="acquistatoFarmRda"
                                            acquistato={true}
                                            soloAcquistato={true}
                                        />
                                        {loadPanelAcquistato}
                                    </Col>
                                    {autorizzazione && !autorizzazione.includes("O") &&

                                        <Col sm='6' id="colAcquistatoAltraFarmRda">
                                            <GrigliaVendutoRda
                                                codiceCsfSelez={codiceCsfSelez}
                                                farmSelez={altraFarmSelez}
                                                setIsLoadVisible={setIsLoadAltraFarmVisibleAcquistato}
                                                listaFarmacie={props.datiFarmacie}
                                                id="acquistatoFarmRdaAltra"
                                                acquistato={true}
                                                soloAcquistato={true}
                                            />
                                            {loadPanelAcquistatoAltraFarm}
                                        </Col>

                                    }
                                </Row>
                            </td>
                        </tr>
                    </table>
                            

                    </div>
                            
                    {(cercaNuovoProd && inputCerca.length > 0 && farmSelez.length > 0) &&
                        <DialogAggiungiProdOrdine
                            isOpen={isDialogOpen}
                            handleClose={handleClose}
                            svuotaDialog={svuotaDialog}
                            cercaNuovoProd={cercaNuovoProd}
                            testoRicerca={inputCerca}
                            farmSelez={farmSelez}
                            insertProdotto={insertProdotto}
                        />}
                    {(testata && isDialogDiffOpen) &&
                        <DialogDifferenzeRda
                            idOrdine={testata.id}
                            isDialogDiffOpen={isDialogDiffOpen}
                            setIsDialogDiffOpen={setIsDialogDiffOpen}
                    />}

                {(testata && isDialogAggiungiProdottiDittaOpen) &&
                    <DialogAggiungiProdottiDittaRda
                    isDialogAggiungiProdottiDittaOpen={isDialogAggiungiProdottiDittaOpen}
                    handleCloseDialogAggiungiProdottiDitta={handleCloseAggiungiProdottiDitta}
                    setListCodCsf={setListCodCsf}
                    listCodCsf={listCodCsf}
                    setDittaLinea={setDittaLinea}
                    dittaLinea={dittaLinea}
                    idFar={testata.idFar}
                        
                    />}

                {(testata && isDialogScontoOpen) &&
                    <DialogSconto
                    isDialogScontoOpen={isDialogScontoOpen}
                    handleCloseDialogSconto={handleCloseDialogSconto}
                    numeroRighe={selectedRowKeys?.length}
                    functionNext={applicaScontoProdotti}

                    />}

                </>
            }
        </>
    )
}

export default SingoloOrdineRda

