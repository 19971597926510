import notify from 'devextreme/ui/notify';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { RestServices } from '../../services/restServices';
import { TabellaCompleta, TabellaSemplice } from '../../widget/Tabelle';
import { urlBancaDati, urlCommon, urlOrdiniRda } from '../costanti';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { DittaInterface, FornitoriCentrInterface, ListaPagamentiInterface, OggettoInterface } from '../../interfaces/generiche';
import { SelectFarm, Selezione, SelezioneMultipla, SelezionePagamento, TendinaFarm } from '../../widget/Select';
import { LogEmailRDAInterface, TestataOrdineInterface, TrasferimentiRDAInterface, TrasmettiDatiOrdineInterface } from '../../interfaces/ordiniRda';
import { BoxDataOra, BoxFiltro, BoxTestoCerca, Check, NumberBoxFiltro } from '../../widget/Editor';
import { Loading } from '../../widget/Notifications';
import { urlAnagrafiche } from '../costanti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { TabellaCompletaInterface, TabellaSempliceInterface } from '../../interfaces/tabelle';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import Switch from 'devextreme-react/switch';
import { NewTabella } from '../../widget/nuova_tabella/newVersion';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { RadioGroup } from 'devextreme-react/radio-group';
import { confirm } from 'devextreme/ui/dialog';

export const DialogDifferenzeRda = (props: {
    idOrdine: number | undefined,
    isDialogDiffOpen: boolean,
    setIsDialogDiffOpen: (isDialogDiffOpen: boolean) => void
}) => {
    const [differenze, setDifferenze] = useState();
    const [tabellaInstance, setTabellaInstance] = useState();
    useEffect(() => {
        RestServices().rest('POST', props.idOrdine, urlOrdiniRda.getDifferenze).then(result => {
            if (result === true) {
                notify({ position: "center", width: "auto", message: "Non sono presenti modifiche" }, "info", 4000)
            } else {
                setDifferenze(result);
            }
        })
    }, [props.idOrdine]);

    const calculateCustomSummaryDifferenze = (options: any) => {
        if (options.name == "TotaleCostoOriginale") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = options.totalValue + (options.value.costoIniz * options.value.qtaIniziale)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "TotaleCostoModificato") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = options.totalValue + (options.value.costoFinale * options.value.qtaFinale)
                    break;
                case "finalize":
                    break;
            }
        }
    }

    const propsTabDifferenzeRda = useMemo(() => ({
        id: "differenze_ordine_rda",
        dataSource: differenze,
        selection: "none",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        pagination: 10,
        summary: {
            calculateCustomSummary: calculateCustomSummaryDifferenze,
            totalItems: [{
                name: "TotaleDifferenze",
                showInColumn: "Causale",
                displayFormat: "Differenze: {0}",
                summaryType: "count"
            },
                {
                    name: "TotaleQuantitaOriginale",
                    showInColumn: "Qta Originale",
                    column: "qtaIniziale",
                    displayFormat: "Tot.: {0}",
                    summaryType: "sum"
                },
                {
                    name: "TotaleQuantitaOmOriginale",
                    showInColumn: "Qta Om.Orig.",
                    column: "qtaOmIniziale",
                    displayFormat: "Tot.: {0}",
                    summaryType: "sum"
                },
                {
                    name: "TotaleQuantitaOmModificata",
                    showInColumn: "Qta Om.Mod.",
                    column: "qtaOmFinale",
                    displayFormat: "Tot.: {0}",
                    summaryType: "sum"
                },
                {
                    name: "TotaleQuantitaModificata",
                    showInColumn: "Qta Modificata",
                    column: "qtaFinale",
                    displayFormat: "Tot.: {0}",
                    summaryType: "sum"
                },
                {
                    name: "TotaleCostoOriginale",
                    showInColumn: "Costo Orig.",
                    displayFormat: "Tot.: {0}",
                    summaryType: "custom",
                    valueFormat: "#,##0.##",
                }
                ,
                {
                    name: "TotaleCostoModificato",
                    showInColumn: "Costo Mod.",
                    displayFormat: "Tot.: {0}",
                    summaryType: "custom",
                    valueFormat: "#,##0.##",
                }]
        },
        export: {
            enabled: true,
            fileName: "Differenze_Ordine_RDA"
        }
    }), [differenze])
    const columnsDifferenzeRda = CreaColonne([
        { caption: "Causale", dataField: "causale", sortOrder: "asc", sortIndex: 0 },
        { caption: "Prodotto", dataField: "prodotto", sortIndex: 1, sortOrder: "asc" },
        { caption: "Cod.Prod.", dataField: "codProd" },
        { caption: "EAN", dataField: "ean" },
        { caption: "Qta Originale", dataField: "qtaIniziale" },
        { caption: "Qta Modificata", dataField: "qtaFinale" },
        { caption: "Qta Om.Orig.", dataField: "qtaOmIniziale" },
        { caption: "Qta Om.Mod.", dataField: "qtaOmFinale" },
        { caption: "Costo Orig.", dataField: "costoIniz", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Costo Mod.", dataField: "costoFinale", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Sc1 Orig.", dataField: "sconto1Iniz", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Sc1 Mod.", dataField: "sconto1Finale", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Sc2 Orig.", dataField: "sconto2Iniz", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Sc2 Mod.", dataField: "sconto2Finale", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Sc3 Orig.", dataField: "sconto3Iniz", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Sc3 Mod.", dataField: "sconto3Finale", format: { currency: "EUR", precision: 2, type: "fixedPoint" } }
    ])
    const onRowPrepared = useCallback((e: any) => {
        if (e.rowType === "data" && e.data.qtaFinale === 0) {
            //toglie lo sfondo grigio delle celle alternate altrimenti non si vede il nuovo backgroundColor
            e.rowElement.className = "dx-row dx-data-row dx-row-lines dx-column-lines";
            e.rowElement.style.backgroundColor = "rgb(252, 230, 232)";
        }
    }, [])

    return (
        <>
            <Modal
                show={props.isDialogDiffOpen}
                onHide={() => props.setIsDialogDiffOpen(false)}
                dialogClassName="dialogDifferenzeRda"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloDifferenzeRda">
                        <h3>Differenze Ordine RDA</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TabellaCompleta
                        propsTabella={propsTabDifferenzeRda}
                        columns={columnsDifferenzeRda}
                        onRowPrepared={onRowPrepared}
                        setTabellaInstance={setTabellaInstance}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {
                        propsTabDifferenzeRda.dataSource != null &&
                        <Button id="btnStampaPdfOrdine" variant="primary" onClick={() => window.open(urlOrdiniRda.stampaPdfDifferenze + props.idOrdine)}>
                            <FontAwesomeIcon icon={faFilePdf} className="pdfFileIcon" />
                          Stampa PDF
                        </Button>
                    }
                    
                    <Button id="btnChiudiDialogDiffRda" variant="danger" onClick={() => props.setIsDialogDiffOpen(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const DialogPagamento = (props: {
    dialogPagamento: boolean,
    setDialogPagamento: (dialogPagamento: boolean) => void,
    tipoPagamento: number | undefined,
    setTipoPagamento: (pagamento: string) => void,
    setIdTipoPagamento: (idPagamento: number | undefined) => void,
    listaPagamenti: ListaPagamentiInterface[] | undefined,
    idOrdine: number | undefined
}) => {
    const [pagamentoScelto, setPagamentoScelto] = useState<number>();
    const [listaPagamenti, setListaPagamenti] = useState<ListaPagamentiInterface[]>();

    useEffect(() => {
        if (props.tipoPagamento)
            setPagamentoScelto(props.tipoPagamento);
    }, [props.tipoPagamento]);

    useEffect(() => {
        if (props.listaPagamenti) setListaPagamenti(props.listaPagamenti);
    }, [props.listaPagamenti]);

    const cambiaPagamento = () => {
        if (listaPagamenti) {
            const descrPagamento = listaPagamenti.filter(pagamento => {
                return pagamento.ID === pagamentoScelto;
            })[0].DESCRIZIONE
            const param = {
                id: props.idOrdine,
                idTipPagamento: pagamentoScelto
            }
            RestServices().rest('POST', param, urlOrdiniRda.updatePagamento).then(result => {
                if (result.codice != undefined) {
                    notify({ position: "center", width: "auto", message: result.message }, 'error', 3000);
                }
                else if (result === true) {
                    props.setDialogPagamento(false);
                    props.setTipoPagamento(descrPagamento);
                    props.setIdTipoPagamento(pagamentoScelto);
                } else {
                    notify({ position: "center", width: "auto", message: "Non è stato possibile modificare il pagamento. Ci scusiamo per il disagio" }, 'error', 3000);
                }
            })
        }
    }

    const colonneGrid = CreaColonne([
        { dataField: "DESCRIZIONE", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "CODICE", caption: "Codice", width: 120 }
    ])

    const handleClose = () => props.setDialogPagamento(false);
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        setPagamentoScelto(newFiltro.idTipPagamento[0]);
    }


    return (
        <>
            <Modal
                show={props.dialogPagamento}
                onHide={handleClose}
                dialogClassName="dialogPagamentoRda"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloDialogPagamentoRda">
                        <h3>Tipo Di Pagamento</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="dialogPagamentoRdaBody">
                        <Row id="rigaCambiaPagamentoRda">
                            <Col sm="12" id="scrittaCambiaPagamentoRda">
                                Selezionare un tipo di pagamento:
                            </Col>
                            <Col sm="12">
                                {<SelezionePagamento
                                    id="idTipPagamento"
                                    dataSource={listaPagamenti}
                                    impostaFiltri={impostaFiltri}
                                    valueGrid={(pagamentoScelto) ? [pagamentoScelto] : []}
                                    column={colonneGrid}
                                    selection="single"
                                />}
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnOkCambiaPagamentoRda" variant="success" onClick={cambiaPagamento}>
                        OK
                    </Button>
                    <Button id="btnChiudiDialogPagamentoRda" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const DialogFornitore = (props: {
    dialogFornitore: boolean,
    setDialogFornitore: (dialogPagamento: boolean) => void,
    idFornitore: number | undefined,
    setDescrFornitore: (pagamento: string) => void,
    listaFornitori: FornitoriCentrInterface[] | undefined,
    idOrdine: number | undefined,
    idFarm: number | undefined
}) => {
    const [fornitoreScelto, setFornitoreScelto] = useState<number>();
    const [listaFornitori, setListaFornitori] = useState<FornitoriCentrInterface[]>();

    useEffect(() => {
        if (props.idFornitore) setFornitoreScelto(props.idFornitore);
    }, [props.idFornitore]);

    useEffect(() => {
        if (props.listaFornitori) setListaFornitori(props.listaFornitori);
    }, [props.listaFornitori]);

    const cambiaFornitore = () => {
        if (listaFornitori) {
            const descrFornitore = listaFornitori.filter(fornitore => {
                return fornitore.ID === fornitoreScelto;
            })[0].RAGIONE_SOCIALE
            const param = {
                id: props.idOrdine,
                idFornitore: fornitoreScelto,
                idFar: props.idFarm
            }
            RestServices().rest('POST', param, urlOrdiniRda.updateFornitore).then(result => {
                if (result.codice != undefined) {
                    notify({ position: "center", width: "auto", message: result.message }, 'error', 3000);
                }
                else if (result === true) {
                    props.setDialogFornitore(false);
                    props.setDescrFornitore(descrFornitore);
                } else {
                    notify({ position: "center", width: "auto", message: "Non è stato possibile modificare il pagamento. Ci scusiamo per il disagio" }, 'error', 3000);
                }
            })
        }
    }

    const handleClose = () => props.setDialogFornitore(false);
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        setFornitoreScelto(newFiltro.idFornitore);
    }
    return (
        <>
            <Modal
                show={props.dialogFornitore}
                onHide={handleClose}
                dialogClassName="dialogFornitoreRda"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloDialogFornitoreRda">
                        <h3>Fornitore</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="dialogFornitoreRdaBody">
                        <Row id="rigaCambiaFornitoreRda">
                            <Col sm="12" id="scrittaCambiaFornitoreRda">
                                Selezionare un fornitore:
                            </Col>
                            <Col sm="12">
                                {(fornitoreScelto) &&
                                    <Selezione
                                        dataSource={props.listaFornitori}
                                        id="idFornitore"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="RAGIONE_SOCIALE"
                                        valueExpr="ID"
                                        searchEnabled={true}
                                    />
                                }
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnOkCambiaFornitoreRda" variant="success" onClick={cambiaFornitore}>
                        OK
                    </Button>
                    <Button id="btnChiudiDialogFornitoreRda" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}





export const DialogTrasmettiInFarmacia = (props: {
    dialogTrasmettiOpen: boolean,
    setDialogTrasmettiOpen: (dialogTrasmettiOpen: boolean) => void,
    datiOrdine: TrasmettiDatiOrdineInterface | undefined,
    functionNext: (result: boolean) => void
}) => {
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [emailFornitore, setEmailFornitore] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoEmail, setElencoEmail] = useState<LogEmailRDAInterface[]>();
    const [elencoTrasferimenti, setElencoTrasferimenti] = useState<TrasferimentiRDAInterface[]>();
    useEffect(() => {
        if (props.datiOrdine) {
            setFiltri({ idOrdine: props.datiOrdine?.idOrdine })
            const params = {
                idAnaGenFor: props.datiOrdine.idFornitore,
                idFar: props.datiOrdine.idFarmacia
            }
            //RestServices().rest('POST', params, urlAnagrafiche.getEmailFornitoreFarmacia).then(result => {
            //    if (result) {
            //        setEmailFornitore(result.email);
            //    }
            //    else {
            //        setEmailFornitore("");
            //    }

            //})
        }
    }, [props.datiOrdine]);
    useEffect(() => {
        if (filtri.mails) {
            setEmailFornitore(filtri.mails);
        }
    }, [filtri])

    const handleClose = () => {
        props.setDialogTrasmettiOpen(false);
        setFiltri({ idOrdine: props.datiOrdine?.idOrdine });
        setEmailFornitore("")
    }
    const handleOpen = () => {
        if (props.datiOrdine) {
            const params = {
                idAnaGenFor: props.datiOrdine.idFornitore,
                idFar: props.datiOrdine.idFarmacia
            }
            RestServices().rest('POST', params, urlAnagrafiche.getEmailFornitoreGruppo).then(result => {
                if (result.esito) {
                    setEmailFornitore(result.response);
                } else {
                    setEmailFornitore("");
                    notify({ position: "center", width: "auto", message: result.message }, 'error', 3000);
                }
            })

            cercaLogEmail();

            cercaTrasferimenti();
        }
    }
    const trasmettiFarmacia = () => {
        if (props.datiOrdine) {
            setIsVisible(true);
            const url = urlOrdiniRda.inviaFarmacia + props.datiOrdine.idOrdine.toString();
            RestServices().rest('POST', "", url).then(result => {
                setIsVisible(false);
                if (result) {

                    if (result.esito == true) {
                        notify({ position: "center", width: "auto", message: "L'ordine è stato trasmesso con successo" }, 'success', 3000);
                    }
                    else if (result.esito == false) {
                        notify({ position: "center", width: "auto", message: result.message }, 'error', 3000);
                    }

                } else {
                    notify({ position: "center", width: "auto", message: "Si è verificato un problema con la trasmissione dell'ordine. Si prega di riprovare" }, 'error', 3000);
                }
                
                props.functionNext(result);
            })
        }
    }

    const trasmettiTrasfFarmacia = () => {
        if (props.datiOrdine) {
            setIsVisible(true);
            const url = urlOrdiniRda.inviaTrasfFarmacia + props.datiOrdine.idOrdine.toString();
            RestServices().rest('POST', "", url).then(result => {
                setIsVisible(false);
                if (result) {
                    if (result.esito === true) {
                        notify({ position: "center", width: "auto", message: "La procedura di trasferimento merce è stata schedulata con successo" }, 'success', 3000);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, 'error', 3000);
                    }
                    props.functionNext(result);
                }
                
            })
        }
    }

    const inviaEmail = () => {
        if (filtri && filtri.idOrdine && emailFornitore) {
            setIsVisible(true)
            const param = {
                idOrdine: filtri.idOrdine,
                mails: emailFornitore
            }
            RestServices().rest("POST", param, urlOrdiniRda.inviaEmail).then(result => {
                setIsVisible(false)
                if (result === true) {
                    notify({ position: "center", width: "auto", message: "L'email è stata inviata correttamente" }, 'success', 3000);
                } else {
                    notify({ position: "center", width: "auto", message: "Si è verificato un problema con l'invio dell'email al fornitore. Si prega di riprovare" }, 'error', 3000);
                }

                cercaLogEmail();
            })
        } else if (!emailFornitore) {
            notify({ position: "center", width: "auto", message: "Inserire l'email del fornitore" }, 'error', 3000);
        }
    }
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const propsTabLogEmail: TabellaSempliceInterface = {
        id: "logEmail",
        dataSource: elencoEmail,
        filterRow: true,
        pagination: 5,
    }

    const propsTabTrasferimenti: TabellaSempliceInterface = {
        id: "trasferimenti",
        dataSource: elencoTrasferimenti,
        filterRow: true,
        pagination: 5,
    }

    const cercaLogEmail = () => {
        if (props.datiOrdine) {
            const url = urlOrdiniRda.getListEmail + props.datiOrdine.idOrdine.toString();
            RestServices().rest('POST', "", url).then(result => {
                if (result) {
                    setElencoEmail(result);
                }
            })
        }
        
    }

    const cercaTrasferimenti = () => {
        if (props.datiOrdine) {
            const url = urlOrdiniRda.getTrasferimenti + props.datiOrdine.idOrdine.toString();
            RestServices().rest('POST', "", url).then(result => {
                if (result) {
                    setElencoTrasferimenti(result);
                }
            })
        }

    }

    const columnsLogEmail = CreaColonne([
        { caption: "Email", dataField: "email" },
        { caption: "Allegati", dataField: "allegati" },
        { caption: "Data invio", dataField: "dataInvio", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Esito invio", dataField: "esito" },
    ])

    const columnsTrasferimenti = CreaColonne([
        { caption: "Farmacia/Fornitore", dataField: "farmacia" },
        { caption: "Ordine inviato", dataField: "ordineInviato" },
        { caption: "Ordine creato", dataField: "ordineCreato" },
        { caption: "Trasferimento smistato", dataField: "trasferimentoSmistato" },
    ])

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "header") {
            let tooltip = "";
            switch (e.column.dataField) {
                case "ordineInviato":
                    tooltip = "ordine inviato alla farmacia richiedente merce"
                    break;
                case "ordineCreato":
                    tooltip = "ordine creato presso la farmacia richiedente merce"
                    break;
                case "trasferimentoSmistato":
                    tooltip = "richiesta di smistamento presso la farmacia che deve inviare merce "
                    break;
                case "farmacia":
                    tooltip = "farmacia che deve inviare merce"
                    break;

            }
            e.cellElement.title = tooltip;
        }
    }, [])

    return (
        <>
            <Modal
                show={props.dialogTrasmettiOpen}
                onHide={handleClose}
                dialogClassName="dialogTrasmettiInFarmacia"
                centered={true}
                backdrop="static"
                size="lg"
                onEntered={handleOpen}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdineRda">
                        <h3>Trasmissione Ordine</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <div id="dialogTrasmettiBody">
                        <div style={{ gap: 16, flexWrap: 'wrap', background: 'white', padding: 10, borderRadius: 10, marginTop: 15 }}>
                            <Row style={{paddingBottom:12} }>
                                <Col sm="12">
                                    <span id="lbl0" style={{ marginTop: 10 }}><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colonne" /> Legenda bottoni:</span>
                                </Col>

                            </Row>
                            <Row style={{ paddingBottom: 12 }}>
                                <Col sm="12">
                                    <div className='legInfo'>  <p style={{ fontSize: 12, margin: 0, color: 'black' }}><strong>Invia:</strong>  invia l'ordine in farmacia, non è inviata alcuna email  </p></div>
                                </Col>
                            </Row>
                            <Row style={{ paddingBottom: 12 }}>
                                <Col sm="12">
                                    <div className='legInfo'>  <p style={{ fontSize: 12, margin: 0, color: 'black' }}><strong>Invia Email(trasferimento):</strong>   invia email alle farmacie interessate al trasferimento merce e alla farmacia richiedente  </p></div>
                                </Col>
                            </Row>
                            <Row style={{ paddingBottom: 12 }}>
                                <Col sm="12">
                                    <div className='legInfo'>  <p style={{ fontSize: 12, margin: 0, color: 'black' }}><strong>Invia Email(fornitore):</strong>   invia email al fornitore ed alle farmacie interessate al trasferimento merce e alla farmacia richiedente  </p>   </div>
                                </Col>
                            </Row>
                                

                            
                        </div>
                        <Row id="rigaInviaRDAFarmacia">
                            <Col sm="9" className="aggiungiRiga" id='scrittaInviaRDAFarmacia'>
                                Trasmetti ordine in farmacia
                            </Col>

                            

                            <Col sm="3" className="aggiungiRiga">
                                <Button variant="success" onClick={trasmettiFarmacia} id="inviaRDAInFarmacia">Invia</Button>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row id="rigaInviaRDAFarmacia">
                            <Col sm="9" className="aggiungiRiga" id='scrittaInviaRDAFarmacia'>
                                Invia trasferimenti alle farmacie
                            </Col>
                            <Col sm="3" className="aggiungiRiga">
                                <Button variant="primary" onClick={trasmettiTrasfFarmacia} id="inviaRDATrasfInFarmacia">Invia</Button>
                            </Col>
                        </Row>
                        <Row id="tabellaTrasferimenti">

                            <TabellaSemplice propsTabella={propsTabTrasferimenti} columns={columnsTrasferimenti} onCellPrepared={onCellPrepared } />

                        </Row>
                        <Row id="rigaInviaEmailRDA">
                            <Col sm="12" id="scrittaInviaEmailRDA">
                                Invia email al fornitore: <b>{props.datiOrdine?.descrFornitore}</b>
                            </Col>
                            <Col sm="12">
                                <BoxFiltro id="mails" impostaFiltri={impostaFiltri} value={emailFornitore} />
                            </Col>
                            <Col sm="6">
                               
                            </Col>
                            <Col sm="6">
                                <Row>
                                    <Col>
                                        <span className="trasfInfo">Qualora fossero presenti trasferimenti saranno anche schedulati per l'invio alle farmacie</span>
                                    </Col>
                                    <Col>
                                        <Button variant="primary" onClick={inviaEmail} id="inviaEmailRDA">Invia Email</Button>
                                    </Col>
                                </Row>
                                
                                
                            </Col>
                        </Row>
                        <Row id="tabellaLogEmail">
                        
                                <TabellaSemplice propsTabella={propsTabLogEmail} columns={columnsLogEmail} />

                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogTrasmetti" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const DialogNuovoOrdine = (props: {
    dialogNuovoOrdineOpen: boolean,
    setDialogNuovoOrdineOpen: (dialogTrasmettiOpen: boolean) => void,
    functionNext: (result: boolean) => void
    datiFarmacie: ListaFarmacieInterface[] | undefined,
}) => {



    const newOrdineRDA = {
        lstAutorizzazione: [],
        id: 0,
        idFar: 0,
        idStato: 0,
        codiceOrdine: '',
        dataEmissione: '',
        dataConsegna: '',
        dataVariazioneStato: '',
        codiceStato: '',
        descStato: '',
        idFornitore: 0,
        ragioneSociale: '',
        partitaIva: '',
        operatore: '',
        descTipPagamento: '',
        idTipPagamento: 0,
        descTipPagamentoFornitore: '',
        idTipPagamentoFornitore: 0,
        note: '',
        trattati:false
    }
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [filtri, setFiltri] = useState<TestataOrdineInterface>(newOrdineRDA);
    const [fornitori, setFornitori] = useState<any[]>([])
    const [listaPagamenti, setListaPagamenti] = useState<ListaPagamentiInterface[]>();
    const [pagamentoScelto, setPagamentoScelto] = useState<number|undefined>();
    const [btnCreaDisabled, SetBtnCreaDisabled] = useState<boolean>(false)
    const [dataConsegna, setDataConsegna] = useState<string>();
    const [isDialogFiltraProdottiOpen, setIsDialogFiltraProdottiOpen] = useState<boolean>(false);
    const [avvisoFiltri, setAvvisoFiltri] = useState<string>();
    const [listCodCsf, setListCodCsf] = useState<any>([])

    useEffect(() => {

        if (props.dialogNuovoOrdineOpen) {

            //clear input

            
            setPagamentoScelto(undefined)
            const dataInizio = new Date(new Date().getTime());
            setDataConsegna(dataInizio.toString());
            let f: OggettoInterface = {dataConsegna: dataInizio}
            impostaFiltri(f)

            //


            RestServices().rest("POST", "", urlCommon.getListaPagamenti).then(result => {
                if (result) {
                    setListaPagamenti(result);
                }
            })


            if (fornitori.length == 0) {
                setIsVisible(true)
                RestServices().rest('POST', true, urlAnagrafiche.getFornitoreCentral).then(result => {
                    if (result) {
                        setIsVisible(false)
                        setFornitori(result);
                    }

                })
            }
            
        }

    }, [props.dialogNuovoOrdineOpen])

    const impostaFiltri = (newFiltro: OggettoInterface) => {

        //console.log(newFiltro)

        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);

        if (newFiltro.idTipPagamento)
            setPagamentoScelto(newFiltro.idTipPagamento[0]);
        if (newFiltro.dataConsegna)
            setDataConsegna(newFiltro.dataConsegna)
    }



    useEffect(() => {

        //console.log(filtri)

        if ((filtri.idFar == 0 || filtri.idFar == undefined) || (filtri.dataConsegna == undefined || filtri.dataConsegna == "") || (filtri.idFornitore == undefined || filtri.idFornitore == 0) || (pagamentoScelto == undefined || pagamentoScelto == 0) )
            SetBtnCreaDisabled(true)
        else
            SetBtnCreaDisabled(false)

        

        if (listaPagamenti && listaPagamenti.length > 0) {

            if (filtri.idTipPagamento != undefined) {
                let pF = listaPagamenti.filter((item) => {
                    return item.ID === filtri.idTipPagamento
                })[0]

                if (pF)
                    setPagamentoScelto(pF.ID)
            }
        }

        

    }, [filtri])

    useEffect(() => {

        if (filtri.idFornitore && fornitori) {



            let dit = fornitori.filter((item: any) => {
                return item.ID === filtri.idFornitore
            })[0]

            //console.log(dit)

            let rag = dit.RAGIONE_SOCIALE

            //let obj: OggettoInterface = {};
            //obj = { ragioneSociale: rag }
            //impostaFiltri(obj)

            let objD: OggettoInterface = {};
            objD = { idDitta: dit.COD_DITTA_CSF, ragioneSociale:rag}
            impostaFiltri(objD)



        }

       /* if (fornitori) {
            let f = fornitori.filter((forn) => forn.ID == filtri.idFornitore)
            if (f.length > 0) {
                let n: number[] = []
                n.push(f[0].COD_DITTA_CSF)
                let newFiltro: OggettoInterface = {};
                newFiltro["idDitta"] = n
                impostaFiltri(newFiltro);

            }


        }*/

    },[filtri.idFornitore])

    const creaNewOrdineRDA = () => {

       // console.log(filtri)

        let param = {
            idFar: filtri.idFar,
            dataConsegna: filtri.dataConsegna,
            idFornitore: filtri.idFornitore,
            note: filtri.note,
            idTipPagamento: pagamentoScelto,
            listCodCsf: listCodCsf,
            flagIncludiProdotti: filtri.trattati
        }

        RestServices().rest('POST', param, urlOrdiniRda.creaOrdine).then(result => {
            if (!result) {
                notify({ position: "center", width: "auto", message: "Non è stato possibile modificare il campo desiderato. Si prega di riprovare" }, "error", 4000)
                return;
            } else if (result && result.id) {
                notify({ position: "center", width: "auto", message: "Ordine creato con successo" }, "success", 4000)
                // apertura pagina nuovo ordine
                setTimeout(() => {
                    props.setDialogNuovoOrdineOpen(false)
                    window.open("/app/apriOrdineRda?" + result.id, '_blank')
                }, 2000);

                return;
            }
        })



    }

    useEffect(() => {


        if (props.datiFarmacie?.length == 1) {
            let fa: OggettoInterface = { idFar: props.datiFarmacie[0].ID_FAR }
            impostaFiltri(fa)
        }


    }, [props.datiFarmacie])

    useEffect(() => {

        if (listaPagamenti && listaPagamenti.length > 0) {

            //let pag = fornitori.filter((item) => {
            //    return item.ID === filtri.idFornitore
            //})[0].ID_TIP_MOD_PAGAMENTO

            let f  = fornitori.filter((item) => {
                return item.ID === filtri.idFornitore
            })

            let pag: number;
            if (f && f.length > 0) {
                pag = f[0].ID_TIP_MOD_PAGAMENTO
            }

            //console.log(pag)

            let pF = listaPagamenti.filter((item) => {
                return item.ID === pag
            })[0]

            if (pF)
                setPagamentoScelto(pF.ID)
        }
        

    },[filtri.idFornitore])

    const colonneGrid = CreaColonne([
        { dataField: "DESCRIZIONE", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "CODICE", caption: "Codice", width: 120 }
    ])

    const handleOpenDialogFiltraProdotti = () => setIsDialogFiltraProdottiOpen(true);

    const openDialogFitroProdotti = () => {
        handleOpenDialogFiltraProdotti();
    }

    const handleCloseDialogFiltraProdotti = () => {
        setIsDialogFiltraProdottiOpen(false);
       
    }

    useEffect(() => {
        if (listCodCsf && listCodCsf.length > 0) {
            //console.log(listCodCsf)
            setAvvisoFiltri("Sono stati selezionati dei prodotti")
        }
        else
            setAvvisoFiltri("")
    }, [listCodCsf])

    return (

        <>
            {loadPanel}
            <Modal
                show={props.dialogNuovoOrdineOpen}
                onHide={() => { props.setDialogNuovoOrdineOpen(false); setFiltri(newOrdineRDA); }}
                dialogClassName="dialogNewOrdineLibero"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Crea Nuovo Ordine</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}

                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Note: </span>
                        </Col>
                        <Col sm="8">
                            <BoxFiltro id="note" impostaFiltri={impostaFiltri} maxLength={1024} />
                        </Col>
                    </Row>

                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Farmacia: </span>
                        </Col>
                        <Col sm="8">
                            <SelectFarm datiFarmacie={props.datiFarmacie} id="idFar" impostaFiltri={impostaFiltri} displayExpr={true }  />
                        </Col>
                    </Row>
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Fornitore(Ditta): </span>
                        </Col>
                        <Col sm="8">
                            <Selezione
                                dataSource={fornitori}
                                id="idFornitore"
                                impostaFiltri={impostaFiltri}
                                displayExpr="RAGIONE_SOCIALE"
                                valueExpr="ID"
                                searchEnabled={true}
                            />
                        </Col>
                    </Row>

                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Modalità di pagamento: </span>
                        </Col>
                        <Col sm="8">
                            {/*<SelezionePagamento
                                id="idTipPagamento"
                                dataSource={listaPagamenti}
                                impostaFiltri={impostaFiltri}
                                //valueGrid={(pagamentoScelto) ? [pagamentoScelto] : []}
                                column={colonneGrid}
                                selection="single"
                            />*/}

                            <SelezioneMultipla
                                id="idTipPagamento"
                                dataSource={listaPagamenti}
                                column={colonneGrid}
                                selection="single"
                                valueGrid={(pagamentoScelto) ? [pagamentoScelto] : []}
                                valueExpr="ID"
                                displayExpr="DESCRIZIONE"
                                impostaFiltri={impostaFiltri}
                            ></SelezioneMultipla>

                        </Col>
                    </Row>

                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Data prevista consegna: </span>
                        </Col>
                        <Col sm="4">
                            <BoxDataOra id="dataConsegna" impostaFiltri={impostaFiltri} typeDate="date" value={dataConsegna} />
                        </Col>
                    </Row>
                    
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Includi prodotti: </span>
                        </Col>
                        <Col sm="1">
                            <Check disabled={filtri.idFornitore && filtri.idFar ? false : true} id="trattati" impostaFiltri={impostaFiltri} />
                        </Col>
                        <Col sm="2">
                            <Button id="btnIncludiProdotti" variant="success" onClick={openDialogFitroProdotti} disabled={filtri.trattati  ? false : true} >
                                Filtra prodotti
                            </Button>
                        </Col>
                        <Col sm="4">
                            <span id="avvisoFiltri">{avvisoFiltri}</span>
                        </Col>
                    </Row>



                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiNewOrdRDA" variant="success" onClick={creaNewOrdineRDA} disabled={btnCreaDisabled } >
                        Crea
                    </Button>
                    <Button id="btnChiudiDialogNewOrdRDA" variant="danger" onClick={() => { setFiltri(newOrdineRDA); props.setDialogNuovoOrdineOpen(false) }}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

            <DialogFiltraProdottiOrdineRDA
                impostaFiltri={impostaFiltri}
                filtri={filtri}
                handleCloseDialogFiltraProdotti={handleCloseDialogFiltraProdotti}
                isDialogFiltraProdottiOpen={isDialogFiltraProdottiOpen}
                setListCodCsf={setListCodCsf}
                listCodCsf={listCodCsf }
            />
        </>

    )
}

export const DialogClonaOrdine = (props: {
    dialogClonaOrdineOpen: boolean,
    setDialogClonaOrdineOpen: (dialogTrasmettiOpen: boolean) => void,
    functionNext: (result: boolean) => void
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    idOrdine: number,
    idFarmacia:number
}) => {

    const [filtri, setFiltri] = useState<any>();

    const [farmacie, setFarmacie] = useState<ListaFarmacieInterface[]>([]);

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const clonaOrdineRDA = () => {

        let param = {
            id: props.idOrdine,
            idFar: filtri.idFar
        }

        setIsVisible(true)
        RestServices().rest("POST", param, urlOrdiniRda.clonaOrdine).then(result => {
            if (result) {
                if (result.esito) {
                    setIsVisible(false)
                    props.functionNext(result.esito)  
                    props.setDialogClonaOrdineOpen(false)
                }
                else {
                    setIsVisible(false)
                    notify({ position: "center", width: "auto", message: "Non è stato possibile clonare l'ordine. Si prega di riprovare" }, "error", 4000)
                }
            }
        })

    }

    useEffect(() => {

        let far: ListaFarmacieInterface[] = [];

        if (props.datiFarmacie && props.idFarmacia) {

            props.datiFarmacie.forEach((item) => {
                if (item.ID_FAR != props.idFarmacia)
                    far.push(item)
            })

            setFarmacie(far)

        }

    }, [props.datiFarmacie, props.idFarmacia])

    return (

        <>

            <Modal
                show={props.dialogClonaOrdineOpen}
                onHide={() => props.setDialogClonaOrdineOpen(false)}
                dialogClassName="dialogNewOrdineLibero"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Clona Ordine</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Farmacie: </span>
                        </Col>
                        <Col sm="8">
                            <TendinaFarm datiFarmacie={farmacie} id="idFar" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                   

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiNewOrdRDA" variant="success" onClick={clonaOrdineRDA} >
                        Clona
                    </Button>
                    <Button id="btnChiudiDialogNewOrdRDA" variant="danger" onClick={() => { props.setDialogClonaOrdineOpen(false) }}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

        </>

    )

}


export const DialogFiltraProdottiOrdineRDA = (props: {
    isDialogFiltraProdottiOpen: boolean,
    handleCloseDialogFiltraProdotti: () => void,
    impostaFiltri: (newFiltro: object) => void,
    filtri: any,
    setListCodCsf: (listCodCsf: any) => void
    listCodCsf: any[]
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [prodottiDataSource, setProdottiDataSource] = useState<any>([])
    const [ricarica, setRicarica] = useState(false);
    const [codCsf, setCodCsf] = useState<any>([]);
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [disabledTrattati, setDisabledTrattati] = useState<any>();

    useEffect(() => {
        if (props.isDialogFiltraProdottiOpen) {
            
            //verificare se idDitta valorizzato, se si selezionare i prodotti della ditta

            //console.log(props.filtri)

            if (props.filtri.idFornitore && prodottiDataSource.length <= 0) {
                getProdotti()
            }
            if (props.filtri.soloInGiacenza == undefined) {
                let objD: OggettoInterface = {};
                objD.soloInGiacenza = true
                setDisabledTrattati(true)
                props.impostaFiltri(objD)
            }
            if (props.filtri.soloInCommercio == undefined) {
                let objD: OggettoInterface = {};
                objD.soloInCommercio = true
                props.impostaFiltri(objD)
            }
            if (props.filtri.soloTrattati == undefined) {
                let objD: OggettoInterface = {};
                objD.soloTrattati = true
                props.impostaFiltri(objD)
            }
        }

    }, [props.isDialogFiltraProdottiOpen])

    useEffect(() => {

        if (props.filtri.soloInGiacenza != undefined && props.filtri.soloInGiacenza == true) {
            setDisabledTrattati(true)
        }
        else {
            setDisabledTrattati(false)
        }
            

    }, [props.filtri.soloInGiacenza])


    const getProdotti = () => {

        //console.log("start")

        setIsVisible(true);

        let arr: any [] = []
        arr.push(props.filtri.idFar)

        const parametriCerca = {
            testo: "",
            lstFar: arr,
            soloInCommercio: props.filtri.soloInCommercio != undefined ? props.filtri.soloInCommercio : true,
            soloInGiacenza: props.filtri.soloInGiacenza != undefined ? props.filtri.soloInGiacenza : true,
            soloTrattati: props.filtri.soloTrattati != undefined ? props.filtri.soloTrattati : true,
            idDitta: props.filtri.idDitta,
            giacenzaAggiuntiva:true
        }

        //console.log(parametriCerca)

        RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
            .then(result => {

                //console.log("end")
                setIsVisible(false);
                if (result.length >= 1) {
                    setProdottiDataSource(result);
                } else {
                    notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                    setProdottiDataSource([]);
                }
            })


    }

    const salvaFiltriProdottoOrdineLibero = () => {
        //console.log(props.filtri);
        //console.log(codCsf)
        setProdottiDataSource([])
        props.setListCodCsf(codCsf)
        props.handleCloseDialogFiltraProdotti();

    }

    const columns = [
        /*{ caption: "Descrizione", dataField: "descrizione", sortOrder: "asc" },*/
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Cod.Prod", dataField: "minsan" },
        { caption: "EAN", dataField: "ean" },
        { caption: "Giacenza", dataField: "giacenza" },
        { caption: "Giacenza DEP80", dataField: "giacenzaFarmacia", visible: true },
        { caption: "Codice CSF", dataField: "codCsf", visible: false }
    ];

    const onOptionChanged= (id : string, e: any) =>
    {
        let objD: OggettoInterface = {};
        objD[id] = e.value
        props.impostaFiltri(objD)
        setRicarica(true)
    }

    useEffect(() => {
        //console.log("switch")
        //console.log(props.filtri)
        if (ricarica == true) {
            getProdotti()
            setRicarica(false)
        }
        

    }, [ricarica])

    const cambiaCodCsfSel = (value: any) => {

        let ids: number[] =  [];

        value.map((forn: any) => {
            
                ids.push(forn.codCsf)
        })

       //console.log(value)
       //console.log(ids)

        setCodCsf(value)
       
    }

    const onContentReady = (e: any) => {
        //console.log(e)
        //if (tabellaInstance)
        //    console.log(tabellaInstance.instance)
        //tabellaInstance.instance.paging.pageSize = 10
    }

    return (
        <>
            <Modal
                show={props.isDialogFiltraProdottiOpen}
                onHide={() => window.close()}
                dialogClassName="dialogFiltraProdottiOrdineLibero"
                size="xl"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Filtri prodotti nuovo Ordine RDA</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row>&nbsp;</Row>

                    <div>
                        <p>Ditta:<h6> {props.filtri.ragioneSociale}</h6></p>
                    </div>

                    <div className="row">
                        <div className="col-2">
                            <div className="label" >Solo in commercio</div>
                        </div>
                        <div className="col-3">
                            <Switch defaultValue={props.filtri.soloInCommercio != undefined ? props.filtri.soloInCommercio : true}
                                switchedOnText={"SI"}
                                switchedOffText={"NO"}
                                onValueChanged={e => onOptionChanged("soloInCommercio", e)}

                            />
                        </div>
                    </div>
                    <Row>&nbsp;</Row>
                    <div className="row">
                        <div className="col-2">
                            <div className="label" >Solo in giacenza</div>
                        </div>
                        <div className="col-3">
                            <Switch defaultValue={props.filtri.soloInGiacenza != undefined ? props.filtri.soloInGiacenza : true}
                                switchedOnText={"SI"}
                                switchedOffText={"NO"}
                                onValueChanged={e => onOptionChanged("soloInGiacenza", e)}
                            />
                        </div>
                    </div>
                    <Row>&nbsp;</Row>
                    <div className="row">
                        <div className="col-2">
                            <div className="label" >Solo trattati</div>
                        </div>
                        <div className="col-3">
                            <Switch defaultValue={props.filtri.soloTrattati != undefined ? props.filtri.soloTrattati : true}
                                switchedOnText={"SI"}
                                switchedOffText={"NO"}
                                onValueChanged={e => onOptionChanged("soloTrattati", e)}
                                disabled={disabledTrattati}
                                //disabled={true }
                            />
                        </div>
                    </div>

                    

                        

                    <Row>&nbsp;</Row>
                    <Row>
                        <Col sm='12'>

                            <NewTabella
                                idTabella='elenco_prodotti'
                                sync
                                colonne={columns}
                                dataSource={prodottiDataSource}
                                filterRow
                                searchPanel={true}
                                onclickRow={() => null}
                                exportT={{
                                    enabled: false,
                                    fileName: "elenco_prodotti"
                                }}
                                activeSaver={true}
                                columnC={false}
                                multyp
                                keyExpr={"codCsf"}
                                selectedRowKeys={codCsf}
                                selectedValue={(datiRow: any) => cambiaCodCsfSel(datiRow.selectedRowKeys)}
                                filteredDataShow={(e: any) => onContentReady(e)}
                                tabReference={setTabellaInstance}
                                pagination={10}
                                height={480}
                                 
                            />

                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSalvaFiltriProdottoOrdLibero" variant="success" onClick={salvaFiltriProdottoOrdineLibero} >
                        Salva filtri
                    </Button>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="danger" onClick={() => { setProdottiDataSource([]); props.handleCloseDialogFiltraProdotti() }}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogSconto = (props: {
    isDialogScontoOpen: boolean,
    handleCloseDialogSconto: () => void,
    numeroRighe: number|undefined,
    functionNext: (sconto: number) => void
}) => {

    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [disabledSconto, setDisabledSconto] = useState<boolean>(false)

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {


    },[])

    const applicaSconto = () => {


        if (filtri.sconto && filtri.sconto > 0) {
            let a = "ai"
            let p = "prodotti"
            if (props.numeroRighe == 1) {
                a = "a"
                p = "prodotto"
            }
            let result = confirm("<i>Si sta per applicare uno sconto netto del " + filtri.sconto + "% " + a + " " + props.numeroRighe + " " + p + " dell'ordine. Continuare? </i>", "Attenzione");
            result.then((dialogResult) => {
                if (dialogResult) {
                    props.functionNext(filtri.sconto)
                } else {
                    return;
                }
            });
        }
        else {
            notify({ position: "center", width: "auto", message: "Inserire uno sconto" }, "info", 4000)
        }

        

    }

    return (
        <>
            <Modal
                show={props.isDialogScontoOpen}
                onHide={() => window.close()}
                dialogClassName="dialogFiltraProdottiOrdineLibero"
                size="sm"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Sconto prodotti</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="12">

                            <Row>
                                <Col sm='12'>
                                    <NumberBoxFiltro
                                        id={"sconto"}
                                        impostaFiltri={impostaFiltri}
                                        max={100}
                                        format={"#,##0.00"}
                                        step={0.01}
                                    />
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="success" onClick={applicaSconto} >
                        Applica
                    </Button>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="danger" onClick={() => {props.handleCloseDialogSconto() }}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}


export const DialogAggiungiProdottiDittaRda = (props: {
    isDialogAggiungiProdottiDittaOpen: boolean,
    handleCloseDialogAggiungiProdottiDitta: () => void,
    setListCodCsf: (listCodCsf: any) => void,
    listCodCsf: any[],
    setDittaLinea: (ditta: any) => void,
    dittaLinea: any[];
    idFar: number,
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [prodottiDataSource, setProdottiDataSource] = useState<any>([])
    const [ricarica, setRicarica] = useState(false);
    const [codCsf, setCodCsf] = useState<any>([]);
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [disabledTrattati, setDisabledTrattati] = useState<any>();
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [ditte, setDitte] = useState<DittaInterface[]>();
    const [inputCerca, setInputCerca] = useState<string>("");
    const [switchDisabled, setSwitchDisabled] = useState<boolean>(true)

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        
        let id = 'soloLinea'
        let objD: OggettoInterface = {};
        objD[id] = true
        impostaFiltri(objD)

        if (props.dittaLinea != null && props.dittaLinea != undefined && props.dittaLinea.length > 0) {
            setDitte(props.dittaLinea)
            return;
        }
            
        if (props.isDialogAggiungiProdottiDittaOpen && (ditte == null || ditte.length == 0)) {

            //verificare se idDitta valorizzato, se si selezionare i prodotti della ditta

            //console.log(props.filtri)
            if (!ditte || ditte == undefined) {
                setIsVisible(true);
                RestServices().rest('POST', "", urlBancaDati.getDitte).then(result => {
                    if (result) {
                        setIsVisible(false);
                        setDitte(result);
                        props.setDittaLinea(result);
                    }
                })
            }

            
            
        }
        

    }, [props.isDialogAggiungiProdottiDittaOpen])

    useEffect(() => {

        if ((inputCerca == null || inputCerca == undefined || inputCerca == "") &&
        (filtri && filtri.idDitta && filtri.idDitta.length > 0))
            setSwitchDisabled(false)
        else if ((filtri && (filtri.idDitta == undefined || filtri.idDitta.length == 0) && 
        inputCerca !== undefined && inputCerca.length > 0 ))
            setSwitchDisabled(false)
        else
            setSwitchDisabled(true)
    },[filtri.idDitta, inputCerca])

    const getProdotti = () => {


        if ((filtri.idDitta == undefined || !filtri.idDitta || filtri.idDitta.length == 0) &&
            (inputCerca == null || inputCerca == undefined || inputCerca == "")) {
            notify({ position: "center", width: "auto", message: "Inserire il prodotto da cercare o selezionare la ditta/linea" }, "error", 4000)
            return;
        }

        if (filtri && filtri.idDitta && filtri.idDitta.length > 0 &&
            inputCerca && inputCerca != "" && inputCerca != undefined && inputCerca != null && inputCerca.length > 0) {
            notify({ position: "center", width: "auto", message: "Solo un campo tra prodotto e ditta/linea può essere utilizzato" }, "error", 4000)
            return;
            }

        setIsVisible(true);

        let arr: any[] = []
        arr.push(props.idFar)

        const parametriCerca = {
            lstFar: arr,
            testo:inputCerca,
            idDitta: filtri.idDitta != undefined && filtri.idDitta.length > 0 ?  filtri.idDitta[0] : null,
            soloLinea: filtri.soloLinea,
            soloInCommercio: filtri.soloInCommercio != undefined ? filtri.soloInCommercio : true,
            soloInGiacenza: filtri.soloInGiacenza != undefined ? filtri.soloInGiacenza : true,
            soloTrattati: filtri.soloTrattati != undefined ? filtri.soloTrattati : true,
            giacenzaAggiuntiva: true
        }


        RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
            .then(result => {

                setIsVisible(false);
                if (result.length >= 1) {
                    setProdottiDataSource(result);
                } else {
                    notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                    setProdottiDataSource([]);
                }
            })


    }

    const aggiungiProdottiDitta = () => {
        props.setListCodCsf(codCsf)
        setProdottiDataSource([])
        //console.log(codCsf)
        props.handleCloseDialogAggiungiProdottiDitta();

    }

    const columns = [
        /*{ caption: "Descrizione", dataField: "descrizione", sortOrder: "asc" },*/
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Cod.Prod", dataField: "minsan" },
        { caption: "EAN", dataField: "ean" },
        { caption: "Giacenza", dataField: "giacenza" },
        { caption: "Giacenza DEP80", dataField: "giacenzaFarmacia" },
        { caption: "Codice CSF", dataField: "codCsf", visible: false }
    ];


    useEffect(() => {
        //console.log("switch")
        //console.log(props.filtri)
        if (ricarica == true) {
            getProdotti()
            setRicarica(false)
        }


    }, [ricarica])

    const cambiaCodCsfSel = (value: any) => {

        let ids: number[] = [];

        value.map((forn: any) => {

            ids.push(forn.codCsf)
        })

        setCodCsf(value)

    }

    const onContentReady = (e: any) => {
        //console.log(e)
        //if (tabellaInstance)
        //    console.log(tabellaInstance.instance)
        //tabellaInstance.instance.paging.pageSize = 10
    }
    const priorities = ['Ditta', 'Linea'];
    const priorityEntities = [
        { id: 0, text: 'Ditta' },
        { id: 1, text: 'Linea' },
    ];

    const colonneGridGeneriche = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120 }
    ])

    const cerca = () => {


        getProdotti()

    }

    const onValueChanged = (id: string, e: any) => {


        let objD: OggettoInterface = {};
        objD[id] = e.value == 'Ditta' ? false : true

        impostaFiltri(objD)
    }

    const onOptionChanged = (id: string, e: any) => {
        let objD: OggettoInterface = {};
        objD[id] = e.value
        impostaFiltri(objD)
        setRicarica(true)
    }

    return (
        <>
            <Modal
                show={props.isDialogAggiungiProdottiDittaOpen}
                onHide={() => window.close()}
                dialogClassName="dialogFiltraProdottiOrdineLibero"
                size="xl"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Aggiungi prodotti Ditta/Linea</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row>&nbsp;</Row>
                    <Row id="rigaRicerca" style={{ borderTop: 0, paddingTop:0 }}>
                        <Col sm='2' className="ordineRda">
                            <span>Ricerca prodotto</span>
                        </Col>
                        <Col sm='3'>
                            <BoxTestoCerca
                                placeholder="codice, EAN, descrizione" id="ricercaProdottiBD"
                                setInputCerca={setInputCerca}
                                startRicerca={cerca}
                                inputCerca={inputCerca}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            
                            <Row>
                                <Col sm="12" >
                                    <RadioGroup
                                        onValueChanged={e => onValueChanged("soloLinea", e)}
                                        
                                        style={{ marginBottom: 8 }}
                                        items={priorities}
                                        defaultValue={priorities[1]}
                                        layout="horizontal" />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idDitta"
                                        dataSource={ditte}
                                        impostaFiltri={impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="single"
                                        primaSelezionata={false}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm='6'>
                            <Col sm="3">
                                <Button type="button" variant="success" id="btnCercaProdottiDitta" onClick={cerca}>Cerca</Button>
                            </Col>
                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col sm="12">
                            <div className="row">
                                <div className="col-3">
                                    <div className="label" >Solo in commercio</div>
                                </div>
                                <div className="col-3">
                                    <Switch disabled={switchDisabled}
                                        defaultValue={filtri.soloInCommercio != undefined ? filtri.soloInCommercio : true}
                                        switchedOnText={"SI"}
                                        switchedOffText={"NO"}
                                        onValueChanged={e => onOptionChanged("soloInCommercio", e)}

                                    />
                                </div>
                            </div>
                            <Row>&nbsp;</Row>
                            <div className="row">
                                <div className="col-3">
                                    <div className="label" >Solo in giacenza</div>
                                </div>
                                <div className="col-3">
                                    <Switch disabled={switchDisabled}
                                        defaultValue={filtri.soloInGiacenza != undefined ? filtri.soloInGiacenza : true}
                                        switchedOnText={"SI"}
                                        switchedOffText={"NO"}
                                        onValueChanged={e => onOptionChanged("soloInGiacenza", e)}
                                    />
                                </div>
                            </div>
                            <Row>&nbsp;</Row>
                            <div className="row">
                                <div className="col-3">
                                    <div className="label" >Solo trattati</div>
                                </div>
                                <div className="col-3">
                                    <Switch disabled={switchDisabled}
                                        defaultValue={filtri.soloTrattati != undefined ? filtri.soloTrattati : true}
                                        switchedOnText={"SI"}
                                        switchedOffText={"NO"}
                                        onValueChanged={e => onOptionChanged("soloTrattati", e)}
                                    //disabled={true }
                                    />
                                </div>
                            </div>
                            <Row>&nbsp;</Row>

                        </Col>
                    </Row>

                    <Row>
                        <Col sm='12'>

                            <NewTabella
                                idTabella='elenco_prodotti'
                                sync
                                colonne={columns}
                                dataSource={prodottiDataSource}
                                filterRow
                                searchPanel={true}
                                onclickRow={() => null}
                                exportT={{
                                    enabled: false,
                                    fileName: "elenco_prodotti"
                                }}
                                activeSaver={true}
                                columnC={false}
                                multyp
                                keyExpr={"codCsf"}
                                selectedRowKeys={codCsf}
                                selectedValue={(datiRow: any) => cambiaCodCsfSel(datiRow.selectedRowKeys)}
                                filteredDataShow={(e: any) => onContentReady(e)}
                                tabReference={setTabellaInstance}
                                pagination={10}
                                height={480}

                            />

                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSalvaFiltriProdottoOrdLibero" variant="success" onClick={aggiungiProdottiDitta} >
                        Aggiungi prodotti
                    </Button>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="danger" onClick={() => { setProdottiDataSource([]); props.handleCloseDialogAggiungiProdottiDitta() }}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}