import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { DialogConferma, Loading } from '../../widget/Notifications';
import { Selezione, TendinaFarm } from '../../widget/Select';
import { BoxDataOra } from '../../widget/Editor';
import Button from 'react-bootstrap/esm/Button';
import { DrawerFiltri } from '../../widget/Drawer';
import notify from 'devextreme/ui/notify';
import { RestServices } from '../../services/restServices';
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { basenameHostNewTabs, urlConsultazione, urlOrdiniRda } from '../costanti';
import { useAuth } from '../../hooks/useAuth';
import { TabellaCompleta } from '../../widget/Tabelle';
import { OggettoInterface } from '../../interfaces/generiche';
import { ElencoOrdiniInterface, ListaStatoRDAInterface } from '../../interfaces/ordiniRda';
import { OnRowDblClickInterface, TabellaCompletaInterface } from '../../interfaces/tabelle';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { DialogClonaOrdine, DialogNuovoOrdine } from './Dialog';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";


const OrdiniRda = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean}) => {
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const titolo = TitoloContenuto("Ordini RDA");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const auth = useAuth(urlOrdiniRda.auth);
    const authConsultazione = useAuth(urlConsultazione.auth);

    const [openDrawer, setOpenDrawer] = useState(true);
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [aggiornaTabDett, setAggiornaTabDett] = useState(1);

    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [elencoOrdini, setElencoOrdini] = useState<ElencoOrdiniInterface[]>([]);
    const [dal, setDal] = useState<string>();
    const [idOrdineSelez, setIdOrdineSelez] = useState<number>();
    const [isConfermaOpen, setIsConfermaOpen] = useState(false);

    const [dialognuovoOrdine, setDialogNuovoOrdine] = useState(false);
    const [dialogClonaOrdine, setDialogClonaOrdine] = useState(false);

    const [listaStatoRDA, setListaStatoRDA] = useState<ListaStatoRDAInterface[]>();

    const handleCloseConferma = () => setIsConfermaOpen(false);

    const [numeroOrdiniInValutazione, setNumeroOrdiniInValutazione] = useState<number>(0);

    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();

    const [datiCella, setDatiCella] = useState<any>();

    const [idOrdineClona, setIdOrdineClona] = useState<any>();
    const [idFarmaciaClona, setIdFarmaciaClona] = useState<any>();

    const [listaAutorizzazioni, setListaAutorizzazioni] = useState<string[]>([])

    const [listFar, setListFar] = useState<[]>()

    const [elencoFarmacie, setElencoFarmacie] = useState<ListaFarmacieInterface[]>([])

    useEffect(() => {
        const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
        setDal(dataInizio.toString());
        if (props.datiFarmacie && props.datiFarmacie.length > 0) {
            const listaFarmacie: number[] = [];
            props.datiFarmacie.forEach(farm => listaFarmacie.push(farm.ID_FAR));
            setFiltri({
                dal: dataInizio.toString(),
                al: (new Date()).toString(),
                lstFar: listaFarmacie,
                stato: 2
            })
        }
    }, [props.datiFarmacie])

    useEffect(() => {
        if (filtri.lstFar && filtri.lstFar.length === 0) {
            const listaFarmacie: number[] = [];
            elencoFarmacie.forEach(farm => listaFarmacie.push(farm.ID_FAR));
            const nuovoFiltro = { ...filtri, lstFar: listaFarmacie }
            setFiltri(nuovoFiltro);
        }
    }, [filtri.lstFar])



    useEffect(() => {


        if (authConsultazione) {
            let params = {};
            RestServices().rest('POST', params, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        setListFar(result.response)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }

    }, [authConsultazione])

    
    useEffect(() => {
        if (auth && authConsultazione && listFar) {
            if (listaStatoRDA == undefined) {
                const param = {
            };
            RestServices().rest('POST', param, urlOrdiniRda.getListStatoRDA)
                .then(result => {
                    if (result) {
                        setListaStatoRDA(result);
                    }
                })
            }

            let param = {}

            RestServices().rest('POST', param, urlOrdiniRda.getListAutorizzazioni)
                .then(result => {
                    if (result) {
                        setListaAutorizzazioni(result);
                    }
                })

            if (filtri && filtri.dal && filtri.al && filtri.lstFar.length > 0 && listFar.length > 0) {

                //filtri.lstFar = [17]
                filtri.lstFar = []
                let l = [] as ListaFarmacieInterface[];
                listFar.forEach((item: any) => {
                    filtri.lstFar.push(item.idFar)
                    let far  = props.datiFarmacie.filter((far) => {
                        return item.idFar === far.ID_FAR
                    })[0]
                    l.push(far)
                })

                setElencoFarmacie(l);

                startCerca();
            } 
        }
    }, [auth, authConsultazione, listFar])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const creaRiepilogoFiltri = () => {
        let descrFarm: string[] = [""];
        if (props.datiFarmacie && filtri['lstFar']) {
            for (let i = 0; i < filtri['lstFar'].length; i++) {
                descrFarm[i] = props.datiFarmacie.filter((item) => {
                    return item.ID_FAR === filtri['lstFar'][i]
                })[0].DESCRIZIONE
            }
        }
        const elencoFarmSelez = () => {
            if (filtri.lstFar.length === props.datiFarmacie.length) {
                return "Tutte"
            } else {
                const spanDescr = descrFarm.map((descr) => {
                    return <span className="cerca" key={descr}>{descr}&nbsp;</span>
                })
                return spanDescr;
            }
        }

        return (
            <>
                {(filtri.lstFar) && <span className="cerca">&nbsp;Farmacia: {elencoFarmSelez()}</span>}
                {(filtri.dal) && <span className="cerca">&nbsp;Dal: {cambiaFormatoLocalDateTimeToEuropeo(filtri.dal)}</span>}
                {(filtri.al) && <span className="cerca">&nbsp;Al: {cambiaFormatoLocalDateTimeToEuropeo(filtri.al)}</span>}
            </>
        )
    }

    const templateFiltri = () => {
        return (



            <Row>
                <div className="col-md-8">
                    <Row>
                        <Col md="4">
                            <div>
                                <label>Farmacia</label>
                                <TendinaFarm datiFarmacie={elencoFarmacie} id="lstFar" impostaFiltri={impostaFiltri} />
                            </div>
                        </Col>
                        <Col md="2">
                            <div>
                                <label>Dal *</label>
                                <BoxDataOra id="dal" impostaFiltri={impostaFiltri} typeDate="date" value={dal} />
                            </div>
                        </Col >
                        <Col md="2">
                            <div>
                                <label>Al *</label>
                                <BoxDataOra id="al" impostaFiltri={impostaFiltri} typeDate="date" value={(new Date()).toString()} />
                            </div>
                        </Col>

                        <Col md="2">
                            <div >
                                <label>Stato</label>
                                <Selezione id="stato" impostaFiltri={impostaFiltri} dataSource={listaStatoRDA} displayExpr="descrizione" valueExpr="idStato" searchEnabled={true} value={2} />
                            </div>
                        </Col>

                        <Col md="2">
                            <div >
                                <Button style={{ marginTop:23}} type="button" variant="success" id="btnCerca" onClick={startCerca}>CERCA</Button>
                            </div>
                        </Col>

                    </Row>

                </div>


                <div className="col-md-4 text-right">
                    <div className="btn-group" role="group">

                            <Button style={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }} type="button" variant="info" id="btnCreaOrdine" onClick={creaOrdine} title="crea un nuovo ordine">NUOVO ORDINE</Button>
                            {(listaAutorizzazioni && (listaAutorizzazioni.includes("A"))) && <Button style={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }} type="button" variant="info" id="btnInviaOrdine" onClick={inviaOrdine} title="invia gli ordini selezionati in farmacia">INVIA IN FARMACIA</Button>}
                            {(listaAutorizzazioni && (listaAutorizzazioni.includes("A"))) && <Button style={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }} type="button" variant="info" id="btnInviaMail" onClick={inviaMail} title="Invia gli ordini selezionati al fornitore tramite email">INVIA A FORNITORE</Button>}
                    </div>
                </div>
            
            </Row>
            
        )
    }

    const startCerca = () => {
        console.log("filtri")
        console.log(filtri)
        if (filtri) {
            if (!filtri.dal) {
                notify({ position: "center", width: "auto", message: "Selezionare la data di inizio" }, 'error', 4000);
            } else if (!filtri.al) {
                notify({ position: "center", width: "auto", message: "Selezionare la data di fine" }, 'error', 4000);
            } else {
                setIsVisible(true);

                const param = {
                    lstFar: filtri.lstFar,
                    dal: filtri.dal,
                    al: filtri.al,
                    stato: filtri.stato
                }
                param.dal = trasformaDateTimeInLocale(param.dal, true);
                param.al = trasformaDateTimeInLocale(param.al, true);
                RestServices().rest('POST', param, urlOrdiniRda.ordiniList)
                    .then(result => {
                        setIsVisible(false);
                        if (result) {
                            if (result === true) {
                                notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                                setElencoOrdini(result)
                            } else {
                                if (result.length > 0)
                                result.forEach((item: ElencoOrdiniInterface) => {
                                    if (props.datiFarmacie.length > 0) {
                                        const descrFarm = props.datiFarmacie.filter((farm) => {
                                            return farm.ID_FAR === item.idFar
                                        })[0].DESCRIZIONE
                                        item.descrFarm = descrFarm;
                                    }
                                })
                                setOpenDrawer(false);
                                setElencoOrdini(result);
                            }
                        }
                        else if (result && result.message && result.severity) {
                            notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                        } else {
                            notify({ position: "center", width: "auto", message: "Oops c'è stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                        }
                    })
                //RestServices().rest('POST', param, urlOrdiniRda.getTotaleOrdiniValutazione)
                //    .then(result => {
                //        if (result)
                //            if (result > 0)
                //                setNumeroOrdiniInValutazione(result);
                //    })
            }
        } else {
            notify({ position: "center", width: "auto", message: "Inserire i parametri di ricerca richiesti" }, 'error', 4000);
        }
    };

    const propsTabElencoOrdini: TabellaCompletaInterface = {
        id: "elenco_ordine_rda",
        dataSource: elencoOrdini,
        selection: "multiple",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {
            
            totalItems: [{
                name: "TotaleOrdini",
                showInColumn: "Codice Ordine",
                displayFormat: "Ordini: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "Ordini_RDA"
        },
        editing: {
            mode: "cell",
            allowUpdating: true,
            selectTextOnEditStart: true,
        }
    }
    const columnsOrdiniRda = CreaColonne([
        { caption: "Id", dataField: "id", visible: false, allowEditing: false },
        { caption: "Codice Ordine", dataField: "codiceOrdine", allowEditing: false },
        { caption: "Farmacia", dataField: "descrFarm", allowEditing: false },
        { caption: "Ragione Sociale", dataField: "ragioneSociale", allowEditing: false },
        { caption: "Partita IVA", dataField: "partitaIva", allowEditing: false },
        { caption: "Data Emissione", dataField: "dataEmissione", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder: "desc", allowEditing: false },
        { caption: "Tot. importo", allowEditing: false, dataField: "importoTotale", format: { type: "currency", currency: "EUR", precision: 2 } },
        { caption: "Operatore", dataField: "operatore", allowEditing: false },
        { caption: "Tipo Pagamento", dataField: "descTipPagamento", allowEditing: false },
        { caption: "Data consegna", dataField: "dataConsegna", format: "dd/MM/yyyy", dataType: "date", allowEditing: false },
        { caption: "Stato", dataField: "descStato", allowEditing: false },
        { caption: "Data Variazione Stato", dataField: "dataVariazioneStato", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", allowEditing: false },
        { caption: "Utente Variazione Stato", dataField: "utenteVariazioneStato", allowEditing: false },
        { caption: "Data Approvazione", dataField: "dataApprovazione", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", allowEditing: false },
        { caption: "Utente Approvazione", dataField: "utenteApprovazione", allowEditing: false },
        { caption: "Note", dataField: "note", allowEditing: true   },
        { caption: "Email forn.Inviata", dataField: "emailInviata", allowEditing: false   },
        { caption: "Email fornitore", dataField: "emailFornitore", allowEditing: false },
        { caption: "Email inviate", dataField: "emailInviate", allowEditing: false },
        { caption: "Email farm.Inviate", dataField: "emailFarmInviate", allowEditing: false },
        { caption: "Email farmacie", dataField: "emailFarmacie", allowEditing: false },
        { caption: "Trasf. farmacie", dataField: "trasfFarmacie", allowEditing: false },
        {
            caption: "Azioni", type: "buttons", buttons: [
            {
                hint: "Clona",
                icon: "copy",
                onClick: (e: any) => {
                    const idOrdine = e.row.data.id;
                    const idFarmacia = e.row.data.idFar
                    setIdOrdineClona(idOrdine);
                    setIdFarmaciaClona(idFarmacia)
                    setDialogClonaOrdine(true)
                    },
                    visible:  listaAutorizzazioni.includes("O") ? false : true

            },
            {
                hint: "Stampa PDF",
                icon: "pdffile",
                onClick: (e: any) => {
                    const idOrdine = e.row.data.id;
                    window.open(urlOrdiniRda.stampaPdf + idOrdine, "_blank");
                }
            },
            {
                hint: "Elimina Ordine RDA",
                icon: "trash",
                onClick: (e: any) => {
                    const idOrdine = e.row.data.id;
                    setIdOrdineSelez(idOrdine);
                    setIsConfermaOpen(true);
                }
            }]
        }
    ])

    const annullaOrdine = () => {
        if (!idOrdineSelez) return;
        RestServices().rest('POST', idOrdineSelez, urlOrdiniRda.annullaOrdine).then(result => {
            if (result === true) {
                if (elencoOrdini) {
                    const dataAttuale = trasformaDateTimeInLocale(new Date());
                    if (dataAttuale) {
                        const newElenco = elencoOrdini;
                        newElenco.forEach(riga => {
                            if (riga.id === idOrdineSelez) {
                                riga.descStato = "RDA valutata ed annullata";
                                riga.dataVariazioneStato = dataAttuale;
                                riga.idStato = 5;
                            }
                        })
                        setElencoOrdini(newElenco);
                        setAggiornaTabDett(aggiornaTabDett + 1);
                        setIsConfermaOpen(false);
                        notify({ position: "center", width: "auto", message: "L'ordine è stato annullato con successo" }, 'success', 3000);
                    }
                }
            } else {
                notify({ position: "center", width: "auto", message: "Oops c'è stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
            }
        })
    }

    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        if (rowDblClicked.data) {
            const path = basenameHostNewTabs + "apriOrdineRda?" + rowDblClicked.data.id;
            window.open(path);
        }
    }

    //const onRowPrepared = (e: any) => {
    //    //if (e.values) {
    //    //    if (e.data.idStato === 2) {
    //    //        e.rowElement.style.backgroundColor = '#47D0FF';
    //    //    }
    //    //    if (e.data.idStato === 3) {
    //    //        e.rowElement.style.backgroundColor = '#8FFA58';
    //    //    }
    //    //    if (e.data.idStato === 5) {
    //    //        e.rowElement.style.backgroundColor = '#F4ED56';
    //    //    }
    //    //    if (e.data.idStato === 6) {
    //    //        e.rowElement.style.backgroundColor = '#30A6EF';
    //    //    }

    //    //}
            
    //}

    const onCellPrepared = (e: any) => {
        if (e.rowType === "data" && e.column && e.column.caption) {
            if (e.column.caption.startsWith("Stato")) {
                if (e.row.data.idStato === 2) {
                    e.cellElement.style.backgroundColor = '#FA8148';
                }
                else if (e.row.data.idStato === 3) {
                    e.cellElement.style.backgroundColor = '#8FFA58';
                }
                else if (e.row.data.idStato === 5) {
                    e.cellElement.style.backgroundColor = '#F4ED56';
                }
                else if (e.row.data.idStato === 6) {
                    e.cellElement.style.backgroundColor = '#30A6EF';
                }
            }
            if (e.column.caption.startsWith("Note")) {
                if (e.row.data.alertNote === 1) {
                    e.cellElement.style.backgroundColor = '#EFC0AA';
                }
            }
        }
    }

    const onSelectionChanged = (e: any) => {
        var selectedRows = e.selectedRowKeys;
        selectedRows.forEach((row: any) => {
            if (row.nascosto == true)
                e.component.deselectRows(row); return;
        })
    }

    const inviaMailMancanti = () => {
        let param = {}
        RestServices().rest('POST', param, urlOrdiniRda.inviaMailMancanti).then(result => {

            if (result) {
                if (result.esito == true) {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "Invio effettuato con successo" }, "success", 5000);
                }
                else if (result.esito == false) {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: result.message }, "error", 5000);
                }

            }
        })

    }

    const inviaMail = () => {
        setIsVisible(true);
        var keys = tabellaInstance.instance.getSelectedRowKeys();
        if (keys.length == 0) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare almeno un ordine" }, "error", 4000);
        }
        else if (keys.length > 10) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare al massimo 10 ordini" }, "error", 4000);
        }
            
        else {
            
            var item;
            var items = new Array();
            var codici = new Array();
            var codiciOrdine = new Array();
            for (var i = 0; i < keys.length; i++) {
                codici.push(keys[i].id);
                codiciOrdine.push(keys[i].codiceOrdine);
                item = { id: keys[i].id, codice: keys[i].codiceOrdine };
                items.push(item);
            }
            const param = {
                items: items
            }

            
            RestServices().rest('POST', param, urlOrdiniRda.inviaOrdiniFornitore).then(result => {
                
                if (result) {
                    if (result.esito == true) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Invio effettuato con successo" }, "success", 5000);
                    }
                    else if (result.esito == false) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 5000);
                    }
                    setTimeout(() => {
                        startCerca();
                    }, 5000);
                    
                }
            })
            
        }
    }

    const onFocusedCellChanged = useCallback((e: any) => {
        //if (e.column && e.column.dataField && e.row && e.row.data && (e.column.dataField === "note" )) {
        //    tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
        //    tabellaInstance.instance.editCell(e.rowIndex, e.column.dataField);
        //    setDatiCella({
        //        rowIndex: e.rowIndex,
        //        dataField: e.column.dataField
        //    })
        //}
    }, [tabellaInstance])

    const creaOrdine = () => {
        setDialogNuovoOrdine(true)
    }
    const inviaOrdine = () => {
        setIsVisible(true);
        var keys = tabellaInstance.instance.getSelectedRowKeys();
        if (keys.length == 0) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare almeno un ordine" }, "error", 4000);
        }
        else if (keys.length > 10) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare al massimo 10 ordini" }, "error", 4000);
        }
        else {

            var item;
            var items = new Array();
            var codici = new Array();
            var codiciOrdine = new Array();
            for (var i = 0; i < keys.length; i++) {
                codici.push(keys[i].id);
                codiciOrdine.push(keys[i].codiceOrdine);
                item = { id: keys[i].id, codice: keys[i].codiceOrdine };
                items.push(item);
            }
            const param = {
                items: items
            }
            RestServices().rest('POST', param, urlOrdiniRda.inviaOrdiniFarmacia).then(result => {

                if (result) {
                    if (result.esito == true) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Invio effettuato con successo" }, "success", 4000);
                        startCerca();
                    }
                    else if (result.esito == false) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 5000);
                        startCerca();
                    }
                    else if (result.codice) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 5000);
                        startCerca();
                    }

                }
                else {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "Errore imprevisto" }, "error", 5000);
                }
            })

        }
    }


    const onRowUpdating = (e: any) => {
        //if (e.newData.note === 0 || e.newData.note > 0) {
            if (e.newData.note.length > 1024) {
                e.cancel = true;
                notify({ position: "center", width: "auto", message: "Lunghezza massima di caratteri consentiti 1024" }, "error", 4000)
                return;
            }
            const param = {
                id: e.key.id,
                note: e.newData.note
        }
        
            RestServices().rest('POST', param, urlOrdiniRda.updateNote).then(result => {
                if (result && result.esito) {
                    const copiaDati = elencoOrdini;
                    copiaDati.forEach(riga => {
                        if (riga.id === e.key.id) {
                            riga.note = e.key.note;
                        }
                    })
                    setElencoOrdini(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                    startCerca();
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento, si prega di riprovare" }, "error", 4000)
                }
            })
        }
    //}

    const clonaOrdineNext = (e: boolean) => {
        if (e) {
            notify({ position: "center", width: "auto", message: "Ordine clonato con successo" }, "success", 4000)
            setTimeout(() => {
                startCerca()
            }, 4000);
        }
    }

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <DrawerFiltri id="paginaOrdiniRda" templateFiltri={templateFiltri} creaRiepilogoFiltri={creaRiepilogoFiltri} open={openDrawer} setOpen={setOpenDrawer} />

                <div style={{ gap: 16, display: 'flex', flexWrap: 'wrap', background: 'white', padding: 10, borderRadius: 10, marginTop: 15 }}>
                    <span id="lbl0" style={{ marginTop: 10 }}><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colonne" /> Legenda bottoni:</span>
                    <div className='legInfo'>  <p style={{ fontSize: 12, margin: 0, color: 'black' }}><strong>Invia in farmacia:</strong>  invia in farmacia gli ordini selezionati, non è inviata alcuna email  </p>   </div>
                    <div className='legInfo'>  <p style={{ fontSize: 12, margin: 0, color: 'black' }}><strong>Invia a fornitore:</strong>   invia email ai fornitori degli ordini selezionati; se vi è trasferimento merce saranno schedulate le procedure automatiche per il trasfrimento merce verso le farmacie che devono inviarla e sarà spedita una mail alla farmacia richiedente merce </p>   </div>
                </div>

                <Row className="tabellaFornitori">
                    <Col sm="12">
                        {aggiornaTabDett &&
                            <TabellaCompleta
                            propsTabella={propsTabElencoOrdini}
                            columns={columnsOrdiniRda}
                            onRowDblClick={onRowDblClick}
                            setTabellaInstance={setTabellaInstance}
                            //onRowPrepared={onRowPrepared}
                            rowAlternationEnabled={false}
                            onCellPrepared={onCellPrepared}
                            allowColumnResizing={true}
                            selectedRowKeys={selectedRowKeys}
                            onSelectionChanged={onSelectionChanged}
                            onFocusedCellChanged={onFocusedCellChanged}
                            onRowUpdating={onRowUpdating}
                            />}
                    </Col>
                </Row>
            </div>
            <DialogConferma
                dialogIsOpen={isConfermaOpen}
                message="In seguito a questa operazione l'ordine verrà annullato. Si vuole procedere?"
                functionNext={annullaOrdine}
                handleCloseConferma={handleCloseConferma}
            />
            <DialogNuovoOrdine
                datiFarmacie={elencoFarmacie}
                dialogNuovoOrdineOpen={dialognuovoOrdine}
                setDialogNuovoOrdineOpen={setDialogNuovoOrdine}
                functionNext={startCerca}
            />

            <DialogClonaOrdine
                datiFarmacie={props.datiFarmacie}
                idFarmacia={idFarmaciaClona}
                idOrdine={idOrdineClona}
                dialogClonaOrdineOpen={dialogClonaOrdine}
                setDialogClonaOrdineOpen={setDialogClonaOrdine}
                functionNext={clonaOrdineNext}
            />
        </>
    )
}

export default OrdiniRda;